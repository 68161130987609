import "../../../../assets/styles/system/cards.scss";
import { useState } from "react";
import { Spin } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CardsQuestionerComponent = ({
  title,
  description,
  responseType,
  responseOptions,
  placeholder,
  onResponseChange,
  loadingSuggestions,
  extraLabel,
  onRegenerate,
  currentStep,
}) => {
  const [selectedResponse, setSelectedResponse] = useState(
    responseType === "input" ||
    responseType === "checklist" ||
    responseType === "button" || 
    responseType === "button-dropdown"
    ? []
    : {}
  );
  const [inputValues, setInputValues] = useState({});
  const [dateValues, setDateValues] = useState({});
  const [selectedResponses, setSelectedResponses] = useState([]);
  
  const [buttonDropdownData, setbuttonDropdownData] = useState({
    selectedButtonDropdown: '',
    value: '',
    dataPickerMode: 'Month',
    dateValues: null,
  });

  const handleDropdownChange = (optionKey, newValue) => {
    const updatedResponses = {
      ...selectedResponses,
      [optionKey]: newValue,
    };
    setSelectedResponses(updatedResponses);
    onResponseChange(updatedResponses);
  };
  const handleDateChange = (optionKey, date) => {
    const updatedDateValues = {
      ...dateValues,
      [optionKey]: date,
    };
    setDateValues(updatedDateValues);
    onResponseChange(updatedDateValues);
  };

  const handleButtonDropdownChange = (name, value) => {
    const updatedButtonDropdownData = {
      ...buttonDropdownData,
      [name]: value,
    };

    setbuttonDropdownData(updatedButtonDropdownData);
    onResponseChange(updatedButtonDropdownData);
  };


  const isFormValid = () => {
    if (responseType === "input") {
      return Object.keys(inputValues).every((key) => inputValues[key] !== "");
    }
    if (responseType === "button" || responseType === "checklist" || responseType === "button-dropdown") {
      return selectedResponse.length > 0;
    }
    if (responseType === "dropdown") {
      return Object.keys(selectedResponses).every(
        (key) => selectedResponses[key] !== ""
      );
    }
    return true;
  };
  
  const handleResponseChange = (optionKey, newValue) => {
    const updatedInputValues = {
      ...inputValues,
      [optionKey]: newValue || "",
    };

    setInputValues(updatedInputValues);
    onResponseChange(updatedInputValues);
  };

  const handleOptionToggle = (option) => {
    let updatedResponse;

    updatedResponse = selectedResponse.includes(option)
      ? selectedResponse.filter((item) => item !== option)
      : [...selectedResponse, option];

    setSelectedResponse(updatedResponse);
    onResponseChange(updatedResponse);
  };

  const cleanOption = (option) => {
    return option.replace(/['"]/g, "");
  };

  return (
    <div
      style={{
        height: "30vh",
        display: "fixed",
        flexDirection: "column",
        marginTop: "2vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="card-questioner">
        <span>{title}</span>
        <p>{description}</p>

        {extraLabel && <div className="style-extraInfoLabel mb-2"></div>}

        <div className="response-area">
          {responseType === "button"&& (
            <div className="d-flex w-100 flex-wrap gap-1 ">
              {responseOptions.length === 0 && loadingSuggestions ? (
                <div className="col-12">
                  <Spin size="large" />
                </div>
              ) : (
                responseOptions.map((label, index) => (
                  <div className="mb-2" key={index}>
                    <button
                      className={`btn btn-choice ${
                        selectedResponse.includes(label) ? "selected" : ""
                      }`}
                      onClick={() => handleOptionToggle(label)}
                      disabled={loadingSuggestions}
                    >
                      {loadingSuggestions ? (
                        <Spin size="small" />
                      ) : (
                        cleanOption(label)
                      )}
                    </button>
                  </div>
                ))
              )}
            </div>
          )}

          {responseType === "button-dropdown" && (
            <div>
              <div className="d-flex w-100 flex-wrap gap-1 ">
                {responseOptions.length === 0 && loadingSuggestions ? (
                  <div className="col-12">
                    <Spin size="large" />
                  </div>
                ) : (
                  responseOptions.map((label, index) => (
                    <div className="mb-2" key={index}>
                      <button
                        className={`btn btn-choice ${buttonDropdownData.selectedButtonDropdown === label ? "selected" : ""}`}
                        onClick={() => handleButtonDropdownChange('selectedButtonDropdown', label)}
                        disabled={loadingSuggestions}
                      >
                        {loadingSuggestions ? (
                          <Spin size="small" />
                        ) : (
                          cleanOption(label)
                        )}
                      </button>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}

          {responseType === "checklist" && (
            <div className="d-flex w-100 flex-wrap gap-1 ">
              {responseOptions.map((option, index) => (
                <div className="col-12" key={index}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedResponse.includes(option)}
                      onChange={() => handleOptionToggle(option)}
                      required
                    />
                    {cleanOption(option)}
                  </label>
                </div>
              ))}
            </div>
          )}

          {responseType === "dropdown" &&
            Array.isArray(selectedResponse) &&
            selectedResponse.length > 0 && (
              <div className="d-flex w-100 flex-wrap gap-1">
                {selectedResponse
                  .filter((option) => option !== "Yes" && option !== "No")
                  .map((selectedOption, index) => (
                    <div className="Q6 mb-2" key={index}>
                      <label className="label-option">
                        {cleanOption(selectedOption)}
                      </label>
                      <select
                        value={selectedResponses[selectedOption] || ""}
                        onChange={(e) =>
                          handleDropdownChange(selectedOption, e.target.value)
                        }
                        className="response-dropdown"
                        required
                        disabled={loadingSuggestions}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {responseOptions.map((option, idx) => (
                          <option key={idx} value={cleanOption(option)}>
                            {cleanOption(option)}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
              </div>
            )}

          {responseType === "input" && Array.isArray(selectedResponse) && (
            <>
              {currentStep === 8 ? (
                <div className="Q8-input">
                  {selectedResponse
                    .filter((option) => option !== "Yes" && option !== "No")
                    .map((selectedOption, index) => (
                      <div className="Q8 mb-2" key={index}>
                        <label className="label-option">
                          {cleanOption(selectedOption)}
                        </label>
                        <div className="label-responses">
                          <div className="">
                            <span className="Q8-dollar-icon">$</span>
                            <input
                              type="number"
                              value={inputValues[selectedOption] || ""}
                              onChange={(e) =>
                                handleResponseChange(
                                  selectedOption,
                                  e.target.value
                                )
                              }
                              placeholder={"100"}
                              className="Q8input-response"
                              required
                              disabled={loadingSuggestions}
                            />
                          </div>
                          <span className="perMonth">Per Month</span>
                          <span className="from">From</span>
                          <DatePicker
                            selected={dateValues[selectedOption] || null}
                            onChange={(date) =>
                              handleDateChange(selectedOption, date)
                            }
                            className="Q8-input-response"
                            required
                            disabled={loadingSuggestions}
                            placeholderText="DD-MM-YYYY"
                            dateFormat="dd-MMMM-yyyy"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="Q7-input">
                  {selectedResponse
                    .filter((option) => option !== "Yes" && option !== "No")
                    .map((selectedOption, index) => (
                      <div className="Q7 mb-2" key={index}>
                        <label className="label-option">
                          {cleanOption(selectedOption)}
                        </label>
                        <div className="input-wrapper">
                          <span className="dollar-icon">$</span>
                          <input
                            type="number"
                            value={inputValues[selectedOption] || ""}
                            onChange={(e) =>
                              handleResponseChange(
                                selectedOption,
                                e.target.value
                              )
                            }
                            placeholder={"100"}
                            className="input-response"
                            required
                            disabled={loadingSuggestions}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          )}

          {onRegenerate && (
            <div className="mt-3">
              <button
                className="btn btn-regenerate"
                onClick={onRegenerate}
                disabled={loadingSuggestions || !isFormValid()}
              >
                {loadingSuggestions ? <Spin /> : "Re-generate Options"}
              </button>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: '100%', marginBottom: '10px' }}>
        {buttonDropdownData.selectedButtonDropdown && (
          <div style={{ marginTop: '20px', marginBottom: '10px', backgroundColor: 'rgba(61,90,128,1)', borderRadius: '5px', padding: "20px" }}>
            <label style={{ color: 'white', fontSize: '16px', display: 'block', marginBottom: '10px' }}>
              What will be each of the following cost?
            </label>
            <p style={{ color: 'white'}}>
              Although these costs are directly related to sales of this product, they will only be accounted for once. If the costs are payable for each product sold, please choose 'Proportionate' in the question above.
            </p>
            {buttonDropdownData.selectedButtonDropdown === 'Add Option' ? (
              <div style={{ display: 'flex', gap: '10px' }}>
                <input
                  type="text"
                  placeholder="Identifier"
                  onChange={(e) =>
                    handleButtonDropdownChange('selectedButtonDropdown', e.target.value)
                  }
                  className="response-input"
                  required
                  disabled={loadingSuggestions}
                  style={{ flex: 1, padding: '8px', fontSize: '14px', backgroundColor: 'rgba(61,90,128,1)', border: '1px solid', borderColor: 'white'}}
                />
                <input
                  type="text"
                  placeholder="Value"
                  onChange={(e) =>
                    handleButtonDropdownChange('value', e.target.value)
                  }
                  className="response-input"
                  required
                  disabled={loadingSuggestions}
                  style={{ flex: 1, padding: '8px', fontSize: '14px', borderColor: 'blue' }}
                />
              </div>
            ) : (
              <div style={{ display:'flex', flexDirection:'column', gap:'10px', background:'rgba(61,90,128,1)' }}>
                <p style={{ color:'white', fontWeight:'700', fontSize:'22px', marginBottom:'0' }}>{buttonDropdownData.selectedButtonDropdown}</p>
                <div style={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'left', gap: '20px'}}>
                  <div style={{ position: 'relative' }}>
                    <span style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', backgroundColor: 'gray', color: 'white', padding: '2px 10px', borderRadius: '5px', fontSize:'22px', fontWeight:'600'}}>$</span>
                    <input
                      type="number"
                      value={buttonDropdownData.value || ""}
                      onChange={(e) =>
                        handleButtonDropdownChange('value', e.target.value)
                      }
                      placeholder="Cost"
                      style={{ padding: '16px 0px 16px 50px', fontSize: '14px', backgroundColor: 'rgba(61,90,128,1)', color: 'white', font: 'bold', outline: 'none', borderColor: 'white', borderRadius: '10px', appearance: 'none', MozAppearance: 'textfield' }}
                      required
                    />
                  </div>
                  <select
                     value={buttonDropdownData.dataPickerMode || 'month'}
                     onChange={(e) => handleButtonDropdownChange('datePickerMode', e.target.value)}
                    style={{ width: '200px', padding: '16px 8px', fontSize:'18px', fontWeight:'800', backgroundColor:'white', color: 'rgba(61,90,128,1)', font: 'bold', borderRadius: '10px' }}
                  >
                    <option value="month">Per Month</option>
                    <option value="year">Per Year</option>
                  </select>
                  <p style={{ color: 'white', padding:'16px 8px' }}>From</p>
                  <DatePicker
                    selected={buttonDropdownData.dateValues || null}
                    onChange={(date) =>
                      handleButtonDropdownChange('dateValues', date)
                    }
                    style = {{ padding:'16px 8px', backgroundColor: 'white', color: 'rgba(61,90,128,1)', fontSize:'16px', font: 'bold', borderRadius: '10px' }}
                    required
                    disabled={loadingSuggestions}
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MMMM-yyyy"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardsQuestionerComponent;