import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Spin, message, Button } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,LabelList } from 'recharts';
import '../../../../assets/styles/system/ReportPage.scss';
import '../../../../assets/styles/system/main.scss';
const RevenueReportComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState(1);
  const [showCombined, setShowCombined] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('Token');
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/fetch-revenue-report`,
          {
            FinanicialplanId: 1,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }
        );
        const fetchedData = response.data;
    
        // Convert all fetched values to integers
        fetchedData['Month Report'] = fetchedData['Month Report'].map(value => Math.round(value));
        fetchedData['Item Report'] = fetchedData['Item Report'].map(item => ({
          ...item,
          Total: item.Total.map(value => Math.round(value)),
          Items: item.Items.map(subItem => ({
            ...subItem,
            Revenue: subItem.Revenue.map(value => Math.round(value)),
          })),
        }));
    
        setData(fetchedData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spin />;
  }

  if (error) {
    message.error('Failed to load data');
    return null;
  }

  // Generate columns for each month
  const monthColumns = data['Month Report'].map((_, index) => ({
    title: `Month ${index + 1}`,
    dataIndex: `Month${index + 1}`,
    key: `Month${index + 1}`,
    // onHeaderCell: () => ({
    //   style: {
    //     backgroundColor: '#3d5a80',
    //     color: '#fff',
    //   },
    // }),
  }));

  const columns = [
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
      // onHeaderCell: () => ({
      //   style: {
      //     backgroundColor: '#3d5a80',
      //     color: '#fff',
      //   },
      // }),
    },
    ...monthColumns,
  ];

  const monthReportRow = {
    key: 'monthReport',
    Title: 'Month Report',
    ...data['Month Report'].reduce((acc, value, index) => {
      acc[`Month${index + 1}`] = value;
      return acc;
    }, {}),
  };
  
  const transformedData = data['Item Report']
  .filter(item => item.Total.reduce((acc, value) => acc + value, 0) !== 0) // Exclude items with total of 0
  .map((item, index) => ({
    key: `itemReport-${index}`,
    ...item,
    ...item.Total.reduce((acc, value, index) => {
      acc[`Month${index + 1}`] = value;
      return acc;
    }, {}),
  }));

  const expandedRowRender = (record) => {
    const itemColumns = [
      {
        title: 'Element',
        dataIndex: 'Element',
        key: 'Element',
        // onHeaderCell: () => ({
        //   style: {
        //     backgroundColor: '#3d5a80',
        //     color: '#fff',
        //   },
        // }),
      },
      ...record.Total.map((_, index) => ({
        title: `Month ${index + 1}`,
        dataIndex: `Items[${index}]`,
        key: `Items[${index}]`,
        render: (text, item) => item.Revenue[index],
        // onHeaderCell: () => ({
        //   style: {
        //     backgroundColor: '#3d5a80',
        //     color: '#fff',
        //   },
        // }),
      })),
    ];

    return <Table columns={itemColumns} dataSource={record.Items} pagination={false} />;
  };

  // Prepare data for the bar chart based on the selected year or combined view
  let barChartData;
  if (showCombined) {
    barChartData = [1, 2, 3].map((year) => {
      const startIndex = (year - 1) * 12;
      const endIndex = startIndex + 12;
      const total = data['Month Report'].slice(startIndex, endIndex).reduce((acc, value) => acc + value, 0);
      return {
        year: `Year ${year}`,
        value: total,
      };
    });
  } else {
    const startIndex = (selectedYear - 1) * 12;
    const endIndex = startIndex + 12;
    barChartData = data['Month Report'].slice(startIndex, endIndex).map((value, index) => ({
      month: `Month ${index + 1}`,
      value,
    }));
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-2">
          <p className="label">{`Month: ${label}`}</p>
          <p className="intro">{`Value: ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };
  return (
    <div className="system-main-container">
      <h2>Revenue Report</h2>
      <div className="container shadow p-3 mb-3 rounded">

      <ResponsiveContainer width="100%" height={400}>
      <BarChart data={barChartData} margin={{ top: 20 }}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey={showCombined ? 'year' : 'month'} />
          {/* <YAxis /> */}
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Bar dataKey="value" fill="#3d5a80" radius={[10, 10, 0, 0]}>
  <LabelList dataKey="value" position="top" />
</Bar>
          </BarChart>
      </ResponsiveContainer>
      <div className="year-buttons">
        <button className={`year-button ${selectedYear === 1 && !showCombined ? 'active' : ''}`} onClick={() => { setSelectedYear(1); setShowCombined(false); }}>Year 1</button>
        <button className={`year-button ${selectedYear === 2 && !showCombined ? 'active' : ''}`} onClick={() => { setSelectedYear(2); setShowCombined(false); }}>Year 2</button>
        <button className={`year-button ${selectedYear === 3 && !showCombined ? 'active' : ''}`} onClick={() => { setSelectedYear(3); setShowCombined(false); }}>Year 3</button>
        <button className={`year-button ${showCombined ? 'active' : ''}`} onClick={() => setShowCombined(true)}>Show Combined View</button>
      </div>
      </div>
      <Table
  className="report-table rounded"
  columns={columns}
  dataSource={[monthReportRow, ...transformedData]}
  expandedRowRender={expandedRowRender}
  rowKey="Title"
  pagination={false}
  scroll={{ x: "max-content" }}
  expandable={{
    rowExpandable: record => record.key !== 'monthReport',
  }}
/>
    </div>
  );
};

export default RevenueReportComponent;