import React from 'react';

// Components
import RevenueReportComponent from '../../../components/pages/System/Reports/RevenueReportComponent';


const RevenueReport = () => {
  return (
    <>
      <RevenueReportComponent />
    </>
  );
};

export default RevenueReport;
