// Libraries
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { message, Steps, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import '../../../../assets/styles/system/main.scss';

// Components
import CardQuestionerRevenue from "../Cards/CardsQuestionerRevenueComponent";

const { Step } = Steps;

const RevenueComponent = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [conditionalQuestions, setConditionalQuestions] = useState([]);
  const [userResponses, setUserResponses] = useState([]);

  const [loading, setLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('Token');

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/fetch-revenue-questions`,
          {
            FinanicialplanId: 1,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }
        );

        console.log("Response:", response.data);
        setQuestions(response.data.Questions);
        setConditionalQuestions(response.data.Conditional_Questions);
        setCurrentStep(response.data.Current_Step);
        setUserResponses(response.data.UserResponses);

        console.log("Questions fetched successfully:", response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
        message.error("Failed to load questions from the backend.");
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const Push_Followups = (followupQuestions, parentQuestionId, followuptype) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      const parentIndex = updatedQuestions.findIndex((q) => q.id === parentQuestionId);
  
      if (parentIndex > -1) {
        let insertIndex = parentIndex + 1;
        let ResponseIndex = insertIndex;

        if (followuptype === 'Repeated') {
          while (insertIndex < updatedQuestions.length && updatedQuestions[insertIndex]?.Keyelement) {
            insertIndex++;
          }
          ResponseIndex = insertIndex;
          
          followupQuestions.forEach((followupQuestion) => {
            const exists = updatedQuestions.some(q => q.id === followupQuestion.id && q.Keyelement === followupQuestion.Keyelement);
            if (!exists) {
              if(insertIndex < updatedQuestions.length) {
                updatedQuestions.splice(insertIndex, 0, followupQuestion);
              } else {
                updatedQuestions.push(followupQuestion);
              }
              insertIndex++;
            }
          });

          setUserResponses((prevResponses) => {
            const updatedResponses = [...prevResponses];
            followupQuestions.forEach((followupQuestion) => {
              const exists = updatedResponses.some(res => res.QuestionID === followupQuestion.id && res.Keyelement === followupQuestion.Keyelement);
              if (!exists) {
                if(ResponseIndex < updatedResponses.length) {
                  updatedResponses.splice(ResponseIndex, 0, { QuestionID: followupQuestion.id, Keyelement: followupQuestion.Keyelement });
                } else {
                  updatedResponses.push({ QuestionID: followupQuestion.id, Keyelement: followupQuestion.Keyelement });
                }
                ResponseIndex++;
              }
            });
            return updatedResponses;
          });
        } else {
          followupQuestions.forEach((followupQuestion) => {
            const exists = updatedQuestions.some(q => q.id === followupQuestion.id);
            if (!exists) {
              updatedQuestions.splice(insertIndex, 0, followupQuestion);
              insertIndex++;
            }
          });

          if(parentIndex !== -1 && parentIndex < userResponses.length -1) {
            setUserResponses((prevResponses) => {
              const updatedResponses = [...prevResponses];
              followupQuestions.forEach((followupQuestion) => {
                const exists = updatedResponses.some(res => res.QuestionID === followupQuestion.id);
                if (!exists) {
                  updatedResponses.splice(ResponseIndex, 0, { QuestionID: followupQuestion.id });
                  ResponseIndex++;
                }
              });
              return updatedResponses;
            });
          }
        }
      }
      
      return updatedQuestions;
    });
  };

  const Pop_Followups = (followupQuestions) => {
    try {
      if(!followupQuestions || followupQuestions.length === 0) return;

      let Questions_Stack = [];
      let Questions = [];

      followupQuestions.forEach(question => {
        Questions_Stack.push(question);
      });

      while (Questions_Stack.length > 0) {
        const currentQuestion = Questions_Stack.pop();
        Questions.push(currentQuestion);

        const response = userResponses.find(response => response.QuestionID === currentQuestion.id);
        if (response) {
          switch (currentQuestion.responsetype) {
            case 'One-User-Selection':
            case 'One-combobox-Selection':
              console.log("One-User-Selection:", response.Responses);
              let selectedOption = currentQuestion.options.find(option => option.choice === response?.Responses?.Response);
              if (selectedOption) {
                switch (selectedOption.followuptype) {
                  case 'Non-repeated':
                    let followupQuestions = selectedOption?.followupQuestions || [];
                    followupQuestions.forEach(followupQuestion => {
                      Questions_Stack.push(followupQuestion);
                    });
                    break;

                  case 'None':
                    break;

                  default:
                    break;
                }
              }
              break;

            case 'Multi-User-Selection':
              switch (currentQuestion.optionstype) {
                case 'static':
                  let selectedOptions = currentQuestion?.options.find(option => option.choice === response?.Responses);
                  if (selectedOptions) {
                      switch (currentQuestion.followuptype) {
                        case 'Non-repeated':
                          selectedOptions?.forEach(selectedOption => {
                            let followupQuestions = selectedOption?.followupQuestions || [];
                            followupQuestions.forEach(followupQuestion => {
                              Questions_Stack.push(followupQuestion);
                            });
                          });
                          break;

                        case 'None':
                          break;

                        default:
                          break;
                      }
                  }
                  break;

                case 'AI Generated':
                case 'Dynamic':
                  switch (currentQuestion.followuptype) {
                    case 'Non-repeated':
                      let followupQuestions = currentQuestion.followupQuestions || [];
                      followupQuestions.forEach(followupQuestion => {
                        Questions_Stack.push(followupQuestion);
                      });
                      break;

                    case 'Repeated':
                      response.Responses.forEach(responseItem => {
                        let followupQuestions = currentQuestion.followupQuestions || [];
                        followupQuestions.forEach(followupQuestion => {
                          followupQuestion.Keyelement = responseItem.Response;
                          Questions_Stack.push(followupQuestion);
                        });
                      });
                      break;

                      case 'None':
                        break;

                      default:
                        break;
                    }
                    break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
        }
      }

      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        const followupQuestionIds = Questions.map((q) => ({ id: q.id, key: q.Keyelement }));
        const filteredQuestions = updatedQuestions.filter((q) => {
          return !followupQuestionIds.some(followup => followup.id === q.id && followup.key === q.Keyelement);
        });
        return filteredQuestions;
      });

      setUserResponses((prevResponses) => {
        const updatedResponses = [...prevResponses];
        const deletedResponses = Questions.map((q) => ({ id: q.id, key: q?.Keyelement }));
        const filteredResponses = updatedResponses.filter((r) => {
          return !deletedResponses.some(followup => followup.id === r.QuestionID && followup.key === r.Keyelement);
        });
        return filteredResponses;
      });
    } catch (error) {
      console.error("Error popping followup questions:", error);
    }
  };

  const handleResponseChange = (questionId, Response, questionKeyelement) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      
      const existingResponseIndex = updatedResponses.findIndex(
        (response) => response.QuestionID === questionId && response?.Keyelement === questionKeyelement
      );
  
      if (existingResponseIndex > -1) {
        updatedResponses[existingResponseIndex].Responses = Response;
      } else {
        const newResponse = {
          QuestionID: questionId,
          Responses: Response,
        }; 

        if (questionKeyelement) {
          newResponse.Keyelement = questionKeyelement;
        }

        updatedResponses.push(newResponse);
      }
      return updatedResponses;
    });
  };

  const handleDropdownResponseChange = (questionId, DropdownResponses, questionKeyelement) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      const existingResponseIndex = updatedResponses.findIndex(
        (response) => response.QuestionID === questionId && response?.Keyelement === questionKeyelement
      );

      if (existingResponseIndex > -1) {
        updatedResponses[existingResponseIndex].DropdownResponses = DropdownResponses;
        console.log("Updated Responses:", updatedResponses[existingResponseIndex]);
      }
      
      console.log("Updated Responses:", updatedResponses);
      return updatedResponses;
    });
  };

  const saveResponsesToDatabase = async () => {
    const token = localStorage.getItem('Token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/store-revenue-response`,
        {
          FinanicialplanId: 1,
          UserResponses: userResponses,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );
    } catch (error) {
      console.error("Error saving user response:", error);
    }
  };

  const nextStep = () => {
    const currentQuestion = questions[currentStep];
    const currentResponse = userResponses.find(
      (response) => response.QuestionID === currentQuestion?.id
    );

    if (!currentResponse) {
      message.error("Please provide a response before proceeding.");
      return;
    }

    console.log('Current Response:', currentResponse);

    if(currentQuestion?.dropdown) {
      if (!Array.isArray(currentResponse?.DropdownResponses) || currentResponse?.DropdownResponses.length === 0) {
        message.error("Please provide a response before proceeding.");
        return;
      }
    };

    if (currentStep < questions.length - 1) {
      saveResponsesToDatabase();
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  
  const handleSubmit = async () => {
    setLoading(true);
  
    const currentQuestion = questions[currentStep];
    const currentResponse = userResponses.find(
      (response) => response.QuestionID === currentQuestion?.id
    );
  
    if (!currentResponse) {
      message.error("Please provide a response before proceeding.");
      setLoading(false);
      return;
    }
  
    try {
      const token = localStorage.getItem('Token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/submit-revenue-response`,
        {
          FinanicialplanId: 1,
          UserResponses: userResponses,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if(response.data.status === 'success') {
        message.success("Revenue plan saved successfully.");message.success("Revenue plan saved successfully.");
        const user_id = localStorage.getItem('USR_ID');
        navigate(`/dashboard/${user_id}/reports`);
      }
      
    } catch (error) {
      console.error("Error saving the revenue plan:", error);
      message.error("Failed to save the revenue plan.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handle_conditional_questions = () => {
      const newQuestions = [];
      const Questionsremove = [];
    
      conditionalQuestions?.forEach((conditionalQuestion) => {
        const requiredQuestions = conditionalQuestion.Questions_required;
        const hasRequiredResponse = requiredQuestions.some((requiredQuestion) => {
          const responseFound = userResponses.find((response) => response.QuestionID === requiredQuestion.QuestionID && response?.Responses?.length > 0);
          return responseFound;
        });
      
        if (hasRequiredResponse) {
          newQuestions.push(conditionalQuestion);
        } else {
          Questionsremove.push(conditionalQuestion);
        }
      });
    
      setQuestions((prevQuestions) => {
        const filteredNewQuestions = newQuestions.filter(
          (newQuestion) => !prevQuestions.some((prevQuestion) => prevQuestion.id === newQuestion.id)
        );

        return [
          ...prevQuestions,
          ...filteredNewQuestions
        ];
      });

      Pop_Followups(Questionsremove);
    };

    handle_conditional_questions();
  }, [userResponses]);
  
  return (
    <div className="row system-main-container">
      <div className="col-12">
        {loading && (
          <div className="loading-container">
            <Spin indicator={loadingIcon} />
          </div>
        )}

        {!loading && questions.length > 0 && (
          <>
            <Steps current={currentStep} className="hide-on-small">
              {questions.map((question, index) => (
                <Step key={question.id} />
              ))}
            </Steps>

            <div className="d-flex flex-column">
              {questions.length > 0 && (
                <div className="col-12">
                  <CardQuestionerRevenue
                    Question={questions[currentStep]}
                    UserselectedResponse={userResponses[currentStep]?.Responses}
                    DropdownResponses={userResponses[currentStep]?.DropdownResponses}
                    Push_Followups={(followupQuestion) => Push_Followups(followupQuestion, questions[currentStep].id, questions[currentStep].followuptype)}
                    Pop_Followups={Pop_Followups}
                    Fetch_Options={(QuestionIds) => {
                      const result = [];
                      QuestionIds.forEach((option) => {
                        const Question = questions.find((question) => question?.id === option.QuestionID);
                    
                        console.log("Question:", Question);
                    
                        const UserResponses = userResponses.find((response) => response.QuestionID === option.QuestionID);
                        console.log("User Responses:", UserResponses);
                    
                        if (!UserResponses) return;
                    
                        switch (Question.optionstype) {
                          case 'static':
                          case 'AI Generated':
                          case 'Dynamic':
                            UserResponses.Responses.forEach((response) => {
                              if (typeof response === 'string') {
                                result.push(response);
                              } else if (response && response.Response) {
                                result.push(response.Response);
                              }
                            });
                            break;
                    
                          case 'Variable':
                            UserResponses.Responses?.forEach((response) => {
                              if (response && response.choice) {
                                result.push(response.choice);
                              }
                            });
                            break;
                    
                          default:
                            break;
                        }
                      });
                      console.log("Options:", result);
                      return result;
                    }}
                    onResponseChange={(response) => {
                      handleResponseChange(
                        questions[currentStep]?.id,
                        response,
                        questions[currentStep]?.Keyelement
                      );
                    }}
                    onDropdownResponseChange={(DropdownResponses) => {
                      handleDropdownResponseChange(
                        questions[currentStep]?.id,
                        DropdownResponses,
                        questions[currentStep]?.Keyelement
                      );
                    }}
                    currentStep={currentStep}
                    financialStep={1}
                  />
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center mt-5">
              {currentStep > 0 && (
                <button
                  className="btn btn-previous-questioner"
                  onClick={prevStep}
                >
                  Previous
                </button>
              )}

              {currentStep < questions.length - 1 && (
                <button
                  className={`btn btn-next-questioner ${
                    currentStep === 0 ? "full-width" : ""
                  }`}
                  onClick={nextStep}
                >
                  Next
                </button>
              )}

              {currentStep === questions.length - 1 && (
                <button
                  className="btn btn-done-questioner"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>
          </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RevenueComponent;
