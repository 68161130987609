import React, { useEffect, useState } from 'react'

// Icons and Inputs
import DatePicker from "react-datepicker";

// Styles
import "../../../../assets/styles/system/cards.scss";
import "react-datepicker/dist/react-datepicker.css";

// Tools
import VariableTool from './VariableTool';

// CSS to hide the arrows
const hideArrowsStyle = {
  appearance: 'textfield',
  MozAppearance: 'textfield',
  WebkitAppearance: 'none',
};

const DropdownTool = ({ dropdownQuestion, dropdownResponse, handleDropdownResponses, Pop_DropdownQuestions, Push_DropdownQuestions }) => {
    const [selectedResponse, setSelectedResponse] = useState(null);

    const handleSelectedResponse = () => {
        if(dropdownResponse){
            return dropdownResponse;
        }

        switch(dropdownQuestion.responsetype) {
            case 'One-combobox-Selection':
              return { Response: '' };
            case 'User-Input-Percentage':
            case 'User-Input-Cost':
              return { Response: '', VariableInputFlag: false };
            case 'User-Input-Finance':
            case 'User-Input-Sales':
              return { Response: { Value: '', Type: '', Date: null }, VariableInputFlag: false };
            case 'User-Input-Customers':
            case 'Finance-Calculator':
              return { Response: { Value: '', Type: '' }, VariableInputFlag: false };
            case 'Finance-Monthly-Calculator':
              return { Response: { Value: '' }, VariableInputFlag: false };
            default:
              return { Response: '', VariableInputFlag: false };
        }
    }

    const resetResponses = () => {
        setSelectedResponse(handleSelectedResponse());
    };

    useEffect(() => {
        setSelectedResponse(handleSelectedResponse());
    }, [dropdownQuestion, dropdownResponse]);

    // Input Handlers
    const handle_Combobox_Selection = (selectedChoice) => {
        setSelectedResponse((prevResponse) => {
            const isSelected = prevResponse.Response === selectedChoice;
            
            const previousOption = dropdownQuestion.options.find((option) => option.choice === prevResponse.Response);
            if (previousOption?.listQuestions) {
                Pop_DropdownQuestions(previousOption.listQuestions);
            }
      
            let updatedResponse;
      
            if (isSelected) {
              updatedResponse = { Response: "" };
            } else {
              updatedResponse = { Response: selectedChoice };
              const selectedOption = dropdownQuestion.options.find((option) => option.choice === selectedChoice);
              Push_DropdownQuestions(selectedOption?.listQuestions);
            }
            
            handleDropdownResponses(updatedResponse, dropdownQuestion.id);
            return updatedResponse;
        });
    };

    const handle_Finance_Input = (field, value) => {
        setSelectedResponse((prevResponse) => {
            const updatedResponse = {
                ...prevResponse,
                Response: {
                    ...prevResponse.Response,
                    [field]: value,
                },
                VariableInputFlag: false,
            };
            handleDropdownResponses(updatedResponse, dropdownQuestion.id);
            console.log("Updated Response", updatedResponse);
            return updatedResponse;
        });
    };

    const handle_Finance_Calculator = (field, value) => {
        setSelectedResponse((prevResponse) => {
            const updatedResponse = {
                ...prevResponse,
                Response: {
                    ...prevResponse.Response,
                    [field]: value,
                },
                VariableInputFlag: false,
            };
            handleDropdownResponses(updatedResponse, dropdownQuestion.id);
            return updatedResponse;
        });
    };

    const handle_Percentage_Input = (value) => {
        setSelectedResponse((prevResponse) => {
          const updatedResponse = {
            ...prevResponse,
            Response: value,
            VariableInputFlag: false
          };
          handleDropdownResponses(updatedResponse, dropdownQuestion.id);
          return updatedResponse;
        });
    }

    const handle_Variable_Input = (value) => {
        setSelectedResponse((prevResponse) => {
          const updatedResponse = {
            ...prevResponse,
            Response: value,
            VariableInputFlag: true,
          };
          handleDropdownResponses(updatedResponse, dropdownQuestion.id);
          return updatedResponse;
        });
    };

    return (
        <div className='mb-2'>
            <span>{dropdownQuestion.title}</span>
            <p>{dropdownQuestion.description}</p>
            <div>
                {dropdownQuestion.responsetype === "One-combobox-Selection" && (
                    <div className="d-flex w-100 flex-wrap gap-1">
                    <select
                        className="form-select"
                        value={selectedResponse?.Response || ""}
                        onChange={(e) => handle_Combobox_Selection(e.target.value)}
                        required
                    >
                        <option value="" disabled>
                        Select an option
                        </option>
                        {dropdownQuestion.options.map((option, index) => (
                        <option key={index} value={option.choice}>
                            {option.choice}
                        </option>
                        ))}
                    </select>
                    </div>
                )}

                {dropdownQuestion.responsetype === "User-Input-Cost" && !selectedResponse?.VariableInputFlag && (
                    <div className="d-flex w-100 flex-wrap gap-1">
                        <span className="Q8-dollar-icon">$</span>
                        <input
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={selectedResponse?.Response || ""}
                            onChange={(e) => handle_Percentage_Input(e.target.value)}
                            placeholder={"Enter Cost"}
                            className="Q8input-response"
                            required
                            style={hideArrowsStyle}
                        />
                    </div>
                )}

                {dropdownQuestion.responsetype === "User-Input-Customers" && !selectedResponse?.VariableInputFlag && (
                    <div className="d-flex w-100 flex-wrap gap-1">
                        <div className="d-flex align-items-center">
                            <div className='' style={{ backgroundColor: '#3D5A80', color: 'white', padding: '0.55rem', borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem' }}>
                                Customers
                            </div>
                            <input type="number" inputMode="numeric" pattern="[0-9]*" className="form-control" placeholder='e.g 10' style={{ ...hideArrowsStyle, borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }} value={selectedResponse?.Response?.Value || ""} onChange={(e) => handle_Finance_Calculator("Value", e.target.value)} />
                        </div>
                        <div>
                        <select className="form-select" value={selectedResponse?.Response?.Type || ""} onChange={(e) => handle_Finance_Input("Type", e.target.value)} required>
                            <option value="" disabled selected>Select a period</option>
                            <option value="Per Month">Per Month</option>
                            <option value="Per Year">Per Year</option>
                        </select>
                        </div>
                    </div>
                )}

                {dropdownQuestion.responsetype === "User-Input-Percentage" && !selectedResponse?.VariableInputFlag && (
                    <div className="d-flex w-100 flex-wrap gap-1">
                        <input
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={selectedResponse?.Response || ""}
                            onChange={(e) => handle_Percentage_Input(e.target.value)}
                            placeholder={"Enter percentage"}
                            className="Q8input-response"
                            required
                            style={{ ...hideArrowsStyle, borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}
                        />
                        <span className="Q8-dollar-icon">%</span>
                    </div>
                )}

                {dropdownQuestion.responsetype === "Finance-Calculator" && (
                    <div className="d-flex w-100 flex-wrap gap-1">
                        <div className="d-flex align-items-center">
                            <div className='' style={{ backgroundColor: '#3D5A80', color: 'white', padding: '0.55rem', borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem' }}>
                                Every
                            </div>
                            <input type="number" inputMode="numeric" pattern="[0-9]*" className="form-control" placeholder='e.g 10' style={{ ...hideArrowsStyle, borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }} value={selectedResponse?.Response?.Value || ""} onChange={(e) => handle_Finance_Calculator("Value", e.target.value)} />
                        </div>
                        <div>
                        <select className="form-select" value={selectedResponse?.Response?.Type || ""} onChange={(e) => handle_Finance_Input("Type", e.target.value)} required>
                            <option value="" disabled selected>Select a period</option>
                            <option value="months">Months</option>
                            <option value="weeks">Weeks</option>
                        </select>
                        </div>
                    </div>
                )}

                {dropdownQuestion.responsetype === "Finance-Monthly-Calculator" && !selectedResponse?.VariableInputFlag && (
                    <div className="d-flex w-100 flex-wrap gap-1">
                        <div style={{ backgroundColor: '#3D5A80', color:'white', padding: '0.5rem', borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem' }}>
                            $
                        </div>
                        <div style={{ flex: 1, padding: '0' }}>
                            <input type="number" inputMode="numeric" pattern="[0-9]*" className="form-control" placeholder='e.g 10' style={{ ...hideArrowsStyle, borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderTopRightRadius: '0', borderBottomRightRadius: '0', width: '100%' }} value={selectedResponse?.Response?.Value || ""} onChange={(e) => handle_Finance_Calculator("Value", e.target.value)} />
                        </div>
                        <div style={{ backgroundColor: '#3D5A80', color:'white', padding: '0.5rem', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}>
                            Every Subscription
                        </div>
                </div>
                )}

                {dropdownQuestion.responsetype === "User-Input-Sales" && !selectedResponse?.VariableInputFlag && (
                    <div className="d-flex w-100 flex-wrap gap-1">
                        <div className="Q8 mb-2">
                            <span className="Q8-dollar-icon">Units</span>
                            <input
                                type="number"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                value={selectedResponse?.Response?.Value || ""}
                                onChange={(e) => handle_Finance_Input("Value", e.target.value)}
                                placeholder={"100"}
                                className="Q8input-response"
                                required
                                style={hideArrowsStyle}
                            />
                        </div>
                        <select
                            value={selectedResponse?.Response?.Type || ""}
                            onChange={(e) => handle_Finance_Input("Type", e.target.value)}
                            className="Q8input-response"
                            required
                        >
                            <option value="" disabled selected>Select a period</option>
                            <option value="Per Month">Per Month</option>
                            <option value="Per Year">Per Year</option>
                        </select>
                        <span className="from">From</span>
                        <DatePicker
                            selected={selectedResponse?.Response?.Date || null}
                            onChange={(date) => handle_Finance_Input("Date", date)}
                            className="Q8-input-response"
                            required
                            placeholderText="DD-MM-YYYY"
                            dateFormat="dd-MMMM-yyyy"
                        />
                    </div>
                )}

                {dropdownQuestion.responsetype === "User-Input-Finance" && !selectedResponse?.VariableInputFlag && (
                    <div className="d-flex w-100 flex-wrap gap-1">
                        <div className="Q8 mb-2">
                            <span className="Q8-dollar-icon">$</span>
                            <input
                                type="number"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                value={selectedResponse?.Response?.Value || ""}
                                onChange={(e) => handle_Finance_Input("Value", e.target.value)}
                                placeholder={"100"}
                                className="Q8input-response"
                                required
                                style={hideArrowsStyle}
                            />
                        </div>
                        <select
                            value={selectedResponse?.Response?.Type || ""}
                            onChange={(e) => handle_Finance_Input("Type", e.target.value)}
                            className="Q8input-response"
                            required
                        >
                            <option value="" disabled selected>Select a period</option>
                            <option value="Per Month">Per Month</option>
                            <option value="Per Year">Per Year</option>
                        </select>
                        <span className="from">From</span>
                        <DatePicker
                            selected={selectedResponse?.Response?.Date || null}
                            onChange={(date) => handle_Finance_Input("Date", date)}
                            className="Q8-input-response"
                            required
                            placeholderText="DD-MM-YYYY"
                            dateFormat="dd-MMMM-yyyy"
                        />
                    </div>
                )}

                {dropdownQuestion.VariableEnable === "true" && (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div tyle={{display: "flex", flexDirection: "row"}}>
                            <div className="form-check form-switch p-0 m-0">

                            <input
                            id="flexSwitchCheckDefault"
                                type="checkbox"
                                className='form-check-input ms-0 me-1'
                                checked={selectedResponse?.VariableInputFlag || false}
                                onChange={() => {
                                    const updatedResponse = {
                                      ...selectedResponse,
                                      VariableInputFlag: !selectedResponse?.VariableInputFlag,
                                    };
                                    setSelectedResponse(updatedResponse);
                                    if (!updatedResponse.VariableInputFlag) {
                                        resetResponses();
                                    }
                                }}
                                style={{marginLeft: "10px"}}
                            />
                            <label className='form-check-label' for="flexSwitchCheckDefault">Variable Input</label>
                            </div>
                        </div>

                        {selectedResponse?.VariableInputFlag && (
                            <VariableTool
                                Data={selectedResponse?.Response}
                                onDataChange={(value) => handle_Variable_Input(value)}
                            />
                        )}
                    </div>
                )}

            </div>
        </div>
    )
}

export default DropdownTool;