import React, { useState, useEffect } from "react";
import { message, Steps, Spin, Modal, Typography, Input, Button } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import CardsQuestionerDirectExpenses from "../Cards/CardsQuestionerDirectExpenses";
import axios from "axios";
import prompts from "../../../../data/prompts.json";

const { Step } = Steps;
const { Title } = Typography;

const DirectExpensesComponent = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [userResponses, setUserResponses] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const loadingIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    useEffect(() => {
        const fetchQuestions = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('Token');

                const response = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/fetch-direct-expenses-questions`,
                    {
                        FinanicialplanId: 1,
                    },
                    {
                        headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        }
                    }
                );

                setQuestions(response.data.Questionner_Questions);
                setCurrentStep(response.data.Current_Step);
                setUserResponses(response.data.Questionner_UserResponses);

                console.log("Questions fetched successfully:", response.data);
            } catch (error) {
                console.error("Error fetching questions:", error);
                message.error("Failed to load questions from the backend.");
            } finally {
                setLoading(false);
            }
        };

        fetchQuestions();
    }, []);

    const Push_Followups = (followupQuestions, parentQuestionId) => {
        console.log(followupQuestions);
        setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        const parentIndex = updatedQuestions.findIndex((q) => q.id === parentQuestionId);
    
        if (parentIndex > -1) {
            updatedQuestions.splice(parentIndex + 1, 0, ...followupQuestions);
        } else {
            updatedQuestions.push(...followupQuestions);
        }
    
        console.log(updatedQuestions);
        return updatedQuestions;
        });
    };

    const Pop_Followups = (followupQuestions) => {
        console.log(followupQuestions);
        setQuestions((prevQuestions) => {
        const updatedQuestions = prevQuestions.filter(
            (question) => !followupQuestions.some(followup => followup.id === question.id)
        );
        return updatedQuestions;
        });
    };

    const handleResponseChange = (questionId, Response) => {
        setUserResponses((prevResponses) => {
        const updatedResponses = [...prevResponses];
        const existingResponseIndex = updatedResponses.findIndex(
            (response) => response.QuestionID === questionId
        );
    
        if (existingResponseIndex > -1) {
            // If the question already exists, update the response
            updatedResponses[existingResponseIndex].Responses = Response;
        } else {
            // If the question does not exist, add a new entry
            updatedResponses.push({
            QuestionID: questionId,
            Responses: Response,
            });
        }
        
        console.log(updatedResponses);
        return updatedResponses;
        });
    };

    const saveResponsesToDatabase = async () => {
        try {
        const token = localStorage.getItem('Token');

        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/store-direct-expenses-response`,
            {
            FinanicialplanId: 1,
            Responses: userResponses,
            },
            {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            }
        );
        } catch (error) {
        console.error("Error saving user response:", error);
        message.error("Failed to save user response.");
        }
    };

    const nextStep = () => {
        const currentQuestion = questions[currentStep];
        const currentResponse = userResponses.find(
        (response) => response.QuestionID === currentQuestion?.id
        );

        if (!currentResponse) {
        message.error("Please provide a response before proceeding.");
        return;
        }

        if (currentStep < questions.length - 1) {
        setCurrentStep(currentStep + 1);
        saveResponsesToDatabase();
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
        }
    };

    const formatRevenuePlan = (text) => {
        return text
        .replace(
            /(Projected Revenues For The Next Three Years:)/gi,
            "<h3>$1</h3>"
        )
        .replace(
            /(Identification And Analysis Of Key Revenue Streams:)/gi,
            "<h3>$1</h3>"
        )
        .replace(/(Calculation Of Gross Margins:)/gi, "<h3>$1</h3>")
        .replace(/(Evaluation Of Potential Growth Areas:)/gi, "<h3>$1</h3>")
        .replace(
            /(Recommendations For Improving Profitability:)/gi,
            "<h3>$1</h3>"
        )
        .replace(/(Revenue Strategies:)/gi, "<h3>$1</h3>")
        .replace(/(Conclusion:)/gi, "<h2>$1</h2>")
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        .replace(/(?:\r\n|\r|\n)/g, "<br />");
    };

    const handleGenerateSummary = async () => {
        const userAnswers = Object.entries(userResponses).map(
        ([questionId, response]) => {
            const question = questions.find((q) => q.id === parseInt(questionId));
            return {
            question: question?.title,
            userAnswer: response,
            };
        }
        );
    
        const formattedUserAnswers = userAnswers
        .map(
            (answer, index) => `
                ${index + 1}. Question: "${answer.question}"
                User Answer: "${answer.userAnswer}"
            `
        )
        .join("");
    
        let revenuePlanPromptTemplate = prompts.financialPlan.generatePlan;
        let revenuePlanPrompt = revenuePlanPromptTemplate.replace(
        "{user_answers}",
        formattedUserAnswers
        );
    
        try {
        const result = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: revenuePlanPrompt }],
            },
            {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                "Content-Type": "application/json",
            },
            }
        );
    
        const generatedRevenuePlan = result.data.choices[0].message.content;
        const formattedRevenuePlan = formatRevenuePlan(generatedRevenuePlan);
        return formattedRevenuePlan;
        } catch (error) {
        console.error("Error generating revenue plan:", error);
        message.error("Failed to generate the revenue plan.");
        return null;
        } finally {
        setLoading(false);
        console.log("data sent successfully");
        }
    };
    
    
    const handleSubmit = async () => {
        setLoading(true);
    
        const currentQuestion = questions[currentStep];
        const currentResponse = userResponses.find(
        (response) => response.QuestionID === currentQuestion?.id
        );
    
        if (!currentResponse) {
        message.error("Please provide a response before proceeding.");
        setLoading(false);
        return;
        }
    
        try {
        const summary = await handleGenerateSummary();
        if (!summary) {
            setLoading(false);
            return;
        }
    
        const token = localStorage.getItem('Token');
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/submit-direct-expenses-response`,
            {
            FinanicialplanId: 1,
            Step_Summary: summary,
            UserResponses: userResponses,
            },
            {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            }
        );
        message.success("Revenue plan saved successfully.");
        } catch (error) {
        console.error("Error saving the revenue plan:", error);
        message.error("Failed to save the revenue plan.");
        } finally {
        setLoading(false);
        }
    };

    return (
        <div>
          <div className="row">
            <div className="col-12">
                {loading && (
                    <div className="loading-container">
                        <Spin indicator={loadingIcon} />
                    </div>
                )}
        
                {!loading && questions.length > 0 && (
                    <>
                        <Steps current={currentStep} className="hide-on-small ">
                            {questions.map((question, index) => (
                            <Step key={question.id} />
                            ))}
                        </Steps>
            
                        <div className="style-steps row">
                            {questions.length > 0 && (
                                <div className="col-12">
                                    <CardsQuestionerDirectExpenses
                                    Question={questions[currentStep]}
                                    UserselectedResponse={userResponses[currentStep]?.Responses}
                                    Push_Followups={Push_Followups}
                                    Pop_Followups={Pop_Followups}
                                    Fetch_Responses={(QuestionID) => {
                                        const userResponse = userResponses.find(response => response.QuestionID === QuestionID);
                                        if (userResponse) {
                                          return userResponse.Responses.map(res => res.Response);
                                        }
                                        return [];
                                    }}
                                    onResponseChange={(response) =>
                                        handleResponseChange(
                                        questions[currentStep].id,
                                        response
                                        )
                                    }
                                    currentStep={currentStep}
                                    financialStep={1}
                                    />
                                </div>
                            )}
                            <div className="d-flex justify-content-between align-items-center mt-5">
                                {currentStep > 0 && (
                                    <button
                                        className="btn btn-previous-questioner"
                                        onClick={prevStep}
                                    >
                                        Previous
                                    </button>
                                )}
                
                                {currentStep < questions.length - 1 && (
                                    <button
                                        className={`btn btn-next-questioner ${
                                        currentStep === 0 ? "full-width" : ""
                                        }`}
                                        onClick={nextStep}
                                    >
                                        Next
                                    </button>
                                )}
                
                                {currentStep === questions.length - 1 && (
                                    <button
                                        className="btn btn-done-questioner"
                                        onClick={handleSubmit}
                                        disabled={loading}
                                    >
                                        {loading ? "Submitting..." : "Submit"}
                                    </button>
                                )}
                            </div>
                        </div>
                    </>    
                )}
            </div>
          </div>
        </div>
    );
};

export default DirectExpensesComponent;