import React from 'react';
import FinincalSetup from '../../components/pages/System/FinincalSetupComponent';

const FinancialSetup = () => {
  return (
    <>
      <FinincalSetup />
    </>
  );
};

export default FinancialSetup;
