import React from 'react';
import { Spin } from "antd";

const SubQuestionComponent = ({ subQuestion, onResponseChange }) => {
  const cleanOption = (option) => option.replace(/['"]/g, "");

  return (
    <div className=" p-3 mb-1" style={{ background: '#3D5A80', borderRadius: '12px', color: '#fff' }}>
      <h5>{subQuestion.title}</h5>
      {/* <p>{subQuestion.description}</p> */}
      <div className="response-area">
        {subQuestion.responseType === "button" && (
          <div className="d-flex w-100 flex-wrap gap-1">
            {subQuestion.responseOptions.length === 0 && subQuestion.loadingSuggestions ? (
              <div className="col-12">
                <Spin size="large" />
              </div>
            ) : (
              subQuestion.responseOptions.map((label, index) => (
                <div className="mb-2" key={index}>
                  <button
                    // className={`btn btn-choice ${subQuestion.selectedResponse.includes(label) ? "selected" : ""}`}
                    onClick={() => onResponseChange(subQuestion.id, label)}
                    disabled={subQuestion.loadingSuggestions}
                  >
                    {subQuestion.loadingSuggestions ? <Spin size="small" /> : cleanOption(label)}
                  </button>
                </div>
              ))
            )}
          </div>
        )}

        {subQuestion.responseType === "checklist" && (
          <div className="d-flex w-100 flex-wrap gap-1">
            {subQuestion.responseOptions.map((option, index) => (
              <div className="col-12" key={index}>
                <label>
                  <input
                    type="checkbox"
                    checked={subQuestion.selectedResponse.includes(option)}
                    onChange={() => onResponseChange(subQuestion.id, option)}
                    required
                  />
                  {cleanOption(option)}
                </label>
              </div>
            ))}
          </div>
        )}

        {subQuestion.responseType === "dropdown" && Array.isArray(subQuestion.selectedResponse) && subQuestion.selectedResponse.length > 0 && (
          <div className="d-flex w-100 flex-wrap gap-1">
            {subQuestion.selectedResponse
              .filter((option) => option !== "Yes" && option !== "No")
              .map((selectedOption, index) => (
                <div className="Q6 mb-2" key={index}>
                  <label className="label-option">
                    {cleanOption(selectedOption)}
                  </label>
                  <select
                    value={subQuestion.selectedResponses[selectedOption] || ""}
                    onChange={(e) => onResponseChange(subQuestion.id, e.target.value)}
                    className="response-dropdown"
                    required
                    disabled={subQuestion.loadingSuggestions}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {subQuestion.responseOptions.map((option, idx) => (
                      <option key={idx} value={cleanOption(option)}>
                        {cleanOption(option)}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
          </div>
        )}

        {subQuestion.responseType === "input" && Array.isArray(subQuestion.selectedResponse) && (
          <div className="Q7-input">
            {subQuestion.selectedResponse
              .filter((option) => option !== "Yes" && option !== "No")
              .map((selectedOption, index) => (
                <div className="Q7 mb-2" key={index}>
                  <label className="label-option">
                    {cleanOption(selectedOption)}
                  </label>
                  <div className="input-wrapper">
                    <span className="dollar-icon">$</span>
                    <input
                      type="number"
                      value={subQuestion.inputValues[selectedOption] || ""}
                      onChange={(e) => onResponseChange(subQuestion.id, e.target.value)}
                      placeholder={"100"}
                      className="input-response"
                      required
                      disabled={subQuestion.loadingSuggestions}
                    />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubQuestionComponent;