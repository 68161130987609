// Libraries
import { useEffect, useState } from "react";
import axios from "axios";

// Icons and Inputs
import DatePicker from "react-datepicker";
import { Spin } from "antd";

// Styles
import "../../../../assets/styles/system/cards.scss";
import "react-datepicker/dist/react-datepicker.css";

const CardsQuestionerDirectExpenses = ({
  Question,
  UserselectedResponse,
  Push_Followups,
  Pop_Followups,
  Fetch_Responses,
  onResponseChange,
  currentStep,
}) => {
  const handleSelectedResponse = () => {
    console.log("UserselectedResponse", UserselectedResponse);
    if (UserselectedResponse) {
      return UserselectedResponse;
    }
  
    switch (Question.responsetype) {
      case "One-User-Selection":
        if(Array.isArray(Question.DropdownElements) && Question.DropdownElements.length > 0){
          return [];
        } else {
          return { Response: "" };
        }
      case "Multi-User-Selection":
        return [];
      case "":
        return [];
      case "User-Input-Text":
      case "User-Input-Percentage":
        return { Response: "" };
      case "Finance-Input":
        return {
          Response: {
            Value: "",
            Type: "",
            Date: new Date(),
          },
        };
    }
  };

  const [selectedResponse, setSelectedResponse] = useState(handleSelectedResponse);

  const handle_One_Selection = (response) => {
    setSelectedResponse((prevResponse) => {
      const isSelected = prevResponse.Response === response.choice;
      let updatedResponse;
  
      if (isSelected) {
        const previousOption = Question.options.find((option) => option.choice === prevResponse.Response);
        console.log("Previous Option", previousOption);
  
        if (previousOption?.followupQuestions) {
          console.log("Popped followups", previousOption.followupQuestions);
          Pop_Followups(previousOption.followupQuestions);
        }
  
        updatedResponse = { Response: "" };
      } else {
        const previousOption = Question.options.find((option) => option.choice === prevResponse.Response);
        console.log("Previous Option", previousOption);
  
        if (previousOption?.followupQuestions) {
          console.log("Popped followups", previousOption.followupQuestions);
          Pop_Followups(previousOption.followupQuestions);
        }
  
        updatedResponse = { Response: response.choice };
  
        if (response?.followupQuestions) {
          console.log("Question.id", Question.id);
          console.log("Pushed followups", response.followupQuestions);
          Push_Followups(response.followupQuestions, Question.id);
        }
      }

      onResponseChange(updatedResponse);
      return updatedResponse;
    });
  };

  const handle_Multi_Selection = (response) => {
    setSelectedResponse((prevResponse) => {
      const isSelected = prevResponse.some((res) => res.Response === response);
      let updatedResponse;
  
      if (isSelected) {
        updatedResponse = prevResponse.filter((res) => res.Response !== response);
      } else {
        updatedResponse = [...prevResponse, { Response: response }];
      }
  
      if (updatedResponse.length === 0) {
        Pop_Followups(Question.followupQuestions);
      } else if (!isSelected && prevResponse.length === 0) {
        if (Question?.followupQuestions) {
          Push_Followups(Question.followupQuestions, Question.id);
        }
      }
  
      onResponseChange(updatedResponse);
      return updatedResponse;
    });
  };

  const handle_Finance_Input = (field, value) => {
    setSelectedResponse((prevResponse) => {
      const updatedResponse = {
        ...prevResponse,
        Response: {
          ...prevResponse.Response,
          [field]: value,
        },
      };
      console.log(updatedResponse);
      onResponseChange(updatedResponse);
      return updatedResponse;
    });
  };

  const handle_User_Input = (value) => {
    setSelectedResponse({ Response: value });
    onResponseChange(selectedResponse);
  };

  /* AI Generation */
  const [loadingAI, setLoadingAI] = useState(false);
  const [AIOptions, setAIOptions] = useState([]);

  const handleLoadAISuggestions = async () => {
    const description = localStorage.getItem("ONBOARDING_BUSSINES_DES");
    console.log(description);

    const prompt = Question.AI_Prompt
    .replace("[BUSINESS_Location]", "Cairo")
    .replace("[BUSINESS_DESCRIPTION]", description)
    .replace("[BUSINESS_CLASSIFICATION]", "Software Engineering")
    .replace("[BUSINESS_REVENEUE_EXPENSES]", Fetch_Responses("51753949-448b-4b86-973e-e00782eff21e"));

    console.log("Prompt", prompt);

    setLoadingAI(true);

    try{
      const result = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      let suggestions = result.data.choices[0].message.content;

      suggestions = suggestions
        .split("\n")
        .map((suggestion) =>
          suggestion
            .replace(/[^a-zA-Z\s]/g, "")
            .trim()
            .split(" ")
            .filter((word) => word)
            .slice(0, 2)
            .join(" ")
        )
        .filter((suggestion) => suggestion.split(" ").length === 2)
        .slice(0, 6);
      
      setAIOptions(suggestions);
      console.log(suggestions);
    } catch(error){
      console.log(error);
    } finally {
      setLoadingAI(false);
      console.log(AIOptions);
    }
  };
  
  const handleAIOptions = async () => {
    try{
      if (UserselectedResponse) {
        setAIOptions(UserselectedResponse.map((res) => res.Response));
      } else {
        handleLoadAISuggestions();
      }
    } catch(error){
      console.log(error);
    }
  };

  /* Dynamic Options */
  const [loadingDynamic, setLoadingDynamic] = useState(false);
  const [dynamicOptions, setDynamicOptions] = useState([]);

  const FetchOptions = async (DynamicOptions) => {
    setLoadingDynamic(true);

    try{
      const token = localStorage.getItem('Token');

      const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/fetch-revenue-response`,
          {
              FinanicialplanId: 1,
              QuestionId: DynamicOptions[0].QuestionID,
          },
          {
              headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              }
          }
      );
      return response.data.QuestionResponse.Responses;
    }
    catch(error){
      console.log(error);
    }
    finally{
      setLoadingDynamic(false);
    }
  };

  const handleDynamicOptions = async () => {
    try{
      const options = await FetchOptions(Question.DynamicOptions);
      setDynamicOptions(options);
      console.log("Dynamic Options", dynamicOptions);
    } catch(error){
      console.log(error);
    }
  };

  useEffect(() => {
    const updatedResponse = handleSelectedResponse();
    setSelectedResponse(updatedResponse);
  }, [currentStep]);

  useEffect(() => {
    setAIOptions([]);
    setDynamicOptions([]);

    if (Question.optionstype === "AI Generated") {
      handleAIOptions();
    } else if(Question.optionstype === "Dynamic") {
      handleDynamicOptions();
    }
  }, [currentStep, Question]);

  return (
    <div
      style={{
        height: "50vh",
        display: "flex",
        marginTop: "5vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="card-questioner">
        <span>{Question.title}</span>
        <p>{Question.description}</p>

        <div className="response-area">
          {Question.responsetype === "One-User-Selection" && (
            <div className="d-flex w-100 flex-wrap gap-1">
              {Question.options.map((option, index) => (
                <div className="mb-2" key={index}>
                  <button
                    className={`btn btn-choice ${
                      selectedResponse.Response === option.choice ? "selected" : ""
                    }`}
                    onClick={() => handle_One_Selection(option)}
                  >
                    {option.choice}
                  </button>
                </div>
              ))}
            </div>
          )}

          {Question.responsetype === "Multi-User-Selection" && Question.optionstype === "AI Generated" && (
            <div className="d-flex w-100 flex-wrap gap-1">
              <button className="btn btn-regenerate" onClick={handleLoadAISuggestions}>
                {loadingAI ? <Spin /> : "Re-generate Options"}
              </button>
              <div className="d-flex w-100 flex-wrap gap-1">
                {AIOptions.map((option, index) => (
                  <div className="mb-2" key={index}>
                    <button
                      className={`btn btn-choice ${
                        Array.isArray(selectedResponse) && selectedResponse.find((res) => res.Response === option) ? "selected" : ""
                      }`}
                      onClick={() => handle_Multi_Selection(option)}
                    >
                      {option}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {Question.responsetype === "Multi-User-Selection" && Question.optionstype === "Dynamic" && (
            <div className="d-flex w-100 flex-wrap gap-1">
              {loadingDynamic && (
                <div className="btn btn-regenerate">
                  <Spin />
                </div>
              )}
              {Array.isArray(dynamicOptions) && dynamicOptions.map((option, index) => (
                <div className="mb-2" key={index}>
                  <button
                    className={`btn btn-choice ${
                      Array.isArray(selectedResponse) && selectedResponse.find((res) => res.Response === option.Response) ? "selected" : ""
                    }`}
                    onClick={() => handle_Multi_Selection(option.Response)}
                  >
                    {option.Response}
                  </button>
                </div>
              ))}
            </div>
          )}

          {Question.responsetype === "User-Input-Percentage" && (
            <div className="d-flex w-100 flex-wrap gap-1">
              <input
                type="number"
                value={selectedResponse.Response || ""}
                onChange={(e) => handle_User_Input(e.target.value)}
                placeholder={"Enter percentage"}
                className="Q8input-response"
                required
              />
              <span className="Q8-dollar-icon">%</span>
            </div>
          )}

          {Question.responsetype === "Finance-Input" && (
            <div className="d-flex w-100 flex-wrap gap-1">
              <div className="Q8 mb-2">
                <span className="Q8-dollar-icon">$</span>
                <input
                  type="number"
                  value={selectedResponse.Response?.Value || ""}
                  onChange={(e) => handle_Finance_Input("Value", e.target.value)}
                  placeholder={"100"}
                  className="Q8input-response"
                  required
                />
              </div>
              <select
                value={selectedResponse.Response?.Type || ""}
                onChange={(e) => handle_Finance_Input("Type", e.target.value)}
                className="Q8input-response"
                required
              >
                <option value="Per Month">Per Month</option>
                <option value="Per Year">Per Year</option>
              </select>
              <span className="from">From</span>

              <DatePicker
                selected={selectedResponse.Response?.Date || null}
                onChange={(date) => handle_Finance_Input("Date", date)}
                className="Q8-input-response"
                required
                placeholderText="DD-MM-YYYY"
                dateFormat="dd-MMMM-yyyy"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardsQuestionerDirectExpenses;
