import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/styles/system/financialSetup.scss';
import Lottie from 'lottie-react';
import arrows from '../../../assets/lottie/arrow.json';
import arrowsVertical from '../../../assets/lottie/arrowsVertical.json';
import axios from 'axios';

const stepsData = [
  {
    step: 'STEP 01',
    title: 'Revenue',
    description: `These questions will record and categorize revenue from sales of products or services. Here you can also document interest on investments, rental income, and other non-operational revenue.`,
    buttonLabel: 'Start'
  },
  {
    step: 'STEP 02',
    title: 'Staffing',
    description: `Here we will ascertain any staff-related costs, also known as labor costs. These costs include not only the salaries and wages paid to employees, but also indirect expenses such as benefits, taxes, training, and recruitment.`,
    buttonLabel: 'Start'
  },
  {
    step: 'STEP 03',
    title: 'Direct Expenses',
    description: `Direct expenses are expenses that can be directly linked to specific goods, services, or projects. These costs often include materials and other expenses related to the production/sales process. These costs are variable and often fluctuate with revenue.`,
    buttonLabel: 'Start'
  },
  {
    step: 'STEP 04',
    title: 'Indirect Expenses',
    description: `Next we calculate any indirect expenses. These are expenses that are not directly associated with specific goods or services. These will often include rent, utilities, insurance and office supplies.`,
    buttonLabel: 'Start'
  },
  {
    step: 'STEP 05',
    title: 'Assets',
    description: `Assets can be tangible or intangible. Tangible assets include physical items like buildings, machinery, inventory, and cash, while intangible assets include non-physical items like patents, trademarks, copyrights, and goodwill.`,
    buttonLabel: 'Start'
  },
  {
    step: 'STEP 06',
    title: 'Funding',
    description: `This is the capital used to finance the operations of a business. The source of this capital could be cash invested by the founder of the business or its directors. However, capital can be raised through various other methods such as issuing shares (equity financing), crowdfunding.`,
    buttonLabel: 'Start'
  }
];

const FinancialSetup = () => {
  const [stepStatusArray, setStepStatusArray] = useState({});
  const [isMobileBig, setIsMobileBig] = useState(window.innerWidth <= 1496);
  const [isMobileSmall, setIsMobileSmall] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();
  const userId = localStorage.getItem('USR_ID');

  useEffect(() => {
    const handleResize = () => {
      setIsMobileBig(window.innerWidth <= 1496);
      setIsMobileSmall(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const fetchStepStatus = async () => {
    try {
      const token = localStorage.getItem('Token');

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/fetch-Status`,
        {
          FinanicialplanId: 1,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log('Step status:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching step status:', error);
      return {};
    }
  };

  const fetchOnboarding = async () => {
    const token = localStorage.getItem('Token');

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/onboarding-fetch`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );

      localStorage.setItem('onboarding_Description', response.data.ONBOARDING_BUSSINES_DES);
      localStorage.setItem('onboarding_Location', response.data.ONBOARDING_SELECTED_COUNTRY);
      localStorage.setItem('onboarding_Classification', response.data.ONBOARDING_SELECTED_AiSUGGESTION);
    } catch (error) {
      console.error('Error fetching onboarding:', error);
    }
  };

  useEffect(() => {
    const updateStepsDataWithStatus = async () => {
      if (userId) {
        const stepStatus = await fetchStepStatus();
        setStepStatusArray(stepStatus);
      }
    };

    fetchOnboarding();
    updateStepsDataWithStatus();
  }, [userId]);

  const formatTitleForUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };

  const handleNavigate = (title) => {
    const formattedTitle = formatTitleForUrl(title);
    navigate(`/dashboard/${userId}/financials-setup/${formattedTitle}`);
  };

  return (
    <>
      <div className='financial-header'>
        <h2>Financials Setup</h2>
        <p>The Financials Setup Assistant simplifies the process of adding financial data. By using this tool, existing data will be reset and new data will be generated based on your responses to the questions. The data can then be fine-tuned and adjusted as needed. It is possible to skip this step and manually input all financial information.</p>
      </div>
      <div className="scrollable-buttons-container-setup">
        {stepsData.map((stepItem, index) => {
          const isDisabled = index >= 1; // Steps 2 to 6

          return (
            <div className='row mt-3' key={index}>
              <div className={isMobileSmall ? 'w-75 mb-1' : 'col-2'}>
                <div 
                  className={`setupStyle d-flex justify-content-center align-items-center ${isDisabled ? 'disabled-setup' : ''}`}
                  onClick={!isDisabled ? () => handleNavigate(stepItem.title) : undefined}
                  style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                >
                  <div>
                    <h3>{stepItem.step}</h3>
                  </div>
                </div>
              </div>
              <div className="col-9 card-style-finicalsetup">
                <div className='row'>
                  <div className={isMobileSmall ? 'text-center' : 'col-7'}>
                    <h4>{stepItem.title}</h4>
                  </div>
                  <div className={isMobileSmall ? 'w-75' : 'col-5'}>
                    <button 
                      className={
                        stepStatusArray[stepItem.title] === "Completed"
                          ? 'btn btn-setup-finincalgreen'
                          : stepStatusArray[stepItem.title] === "Not Completed"
                          ? 'btn btn-setup-finincalyellow'
                          : 'btn btn-setup-finincalred'
                      }
                      onClick={!isDisabled ? () => handleNavigate(stepItem.title) : undefined}
                      disabled={isDisabled}
                      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                      aria-disabled={isDisabled}
                    >
                      {stepStatusArray[stepItem.title] || stepItem.buttonLabel}
                    </button>
                  </div>
                </div>
                <p>{stepItem.description}</p>
              </div>
              {isMobileBig ? (
                <Lottie className="arrow-logo-responsive" animationData={arrowsVertical} />
              ) : (
                <Lottie className="arrow-logo" animationData={arrows} />
              )}
            </div>
          );
        })}

        <div className='row'>
          <div className="col-11">
            <button className='mt-3 btn btn-finish-setup w-100'>Continue</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialSetup;
