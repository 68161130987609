import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Tabs, Input, Select, Button } from 'antd';
import '../../../../assets/styles/system/AutoFillTable.scss';

const { Option } = Select;

const VariableTool = ({ Data, onDataChange }) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 3 }, (_, index) => currentYear + index);

  const [data, setDataState] = useState(() => {
    if (Array.isArray(Data) && Data.length > 0) {
      return Data;
    } else {
      return Array.from({ length: 36 }, (_, index) => ({
        x: index + 1,
        y: 0,
      }));
    }
  });

  const setData = (newData) => {
    setDataState(newData);
    onDataChange(newData);
  };

  const [selectedCells, setSelectedCells] = useState(new Set());
  const [firstValue, setFirstValue] = useState(0);
  const [increaseBy, setIncreaseBy] = useState(0);
  const [increaseType, setIncreaseType] = useState("#");
  const [lastValue, setLastValue] = useState(0);
  const [adjustValue, setAdjustValue] = useState(0);
  const [adjustType, setAdjustType] = useState('#');
  const [activeTab, setActiveTab] = useState("grow");
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [dragStartIndex, setDragStartIndex] = useState(null);

  const handleGrowToolUpdate = () => {
    const step = (lastValue - firstValue) / (selectedCells.size ? selectedCells.size - 1 : 35); // Default to all 36 cells if none selected
    const sortedIndices = selectedCells.size > 0 ? Array.from(selectedCells).sort((a, b) => a - b) : [...Array(36).keys()];
    const updatedData = [...data];
    let i = 0;

    sortedIndices.forEach(index => {
      updatedData[index] = { ...updatedData[index], y:Math.round( firstValue + step * i) };
      i++;
    });

    setData(updatedData);
  };

  const handleReplaceToolUpdate = () => {
    const sortedIndices = selectedCells.size > 0 ? Array.from(selectedCells).sort((a, b) => a - b) : [...Array(36).keys()];
    const updatedData = [...data];

    sortedIndices.forEach((index, i) => {
      let value = firstValue;
      if (increaseType === "#") {
        value += i * increaseBy;
      } else {
        value *= Math.pow(1 + increaseBy / 100, i);
      }
      updatedData[index] = { ...updatedData[index], y:Math.round( value) };
    });

    setData(updatedData);
  };

  const handleAdjustToolUpdate = () => {
    const updatedData = data.map((point, index) => {
      if (selectedCells.size === 0 || selectedCells.has(index)) { // Apply to all if no selection
        const newValue = adjustType === "#" ? point.y + adjustValue : point.y * (1 + adjustValue / 100);
        return {
          ...point,
          y: Math.round(newValue), // Round to integer
        };
      }
      return point;
    });
  
    setData(updatedData);
  };

  const handleMouseDown = (index) => {
    setIsMouseDown(true);
    setDragStartIndex(index);
  };

  const handleMouseMove = (index) => {
    if (isMouseDown) {
      const start = Math.min(dragStartIndex, index);
      const end = Math.max(dragStartIndex, index);
      const newSelection = new Set();
      for (let i = start; i <= end; i++) {
        newSelection.add(i);
      }
      setSelectedCells(newSelection);
    }
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    setDragStartIndex(null);
  };

  const handleTableChange = (index, value) => {
    const updatedData = [...data];
    updatedData[index] = { ...updatedData[index], y: Number(value) };
    setData(updatedData);
  };

  const toggleCellSelection = (index) => {
    const updatedSelection = new Set(selectedCells);

    // If the cell is already selected, remove it
    if (updatedSelection.has(index)) {
      updatedSelection.delete(index);
    } else {
      // Get all the selected indices and sort them
      const sortedSelectedIndices = Array.from(updatedSelection).sort((a, b) => a - b);

      // If there are selected indices, check if the new selection is consecutive
      if (sortedSelectedIndices.length > 0) {
        // Check if the new cell is directly adjacent to the last or first selected cell
        const lastSelectedIndex = sortedSelectedIndices[sortedSelectedIndices.length - 1];
        const firstSelectedIndex = sortedSelectedIndices[0];

        // Ensure the new selection is adjacent to the last selected or first selected
        if (Math.abs(lastSelectedIndex - index) === 1 || Math.abs(firstSelectedIndex - index) === 1) {
          updatedSelection.add(index);
        }
      } else {
        // If no cells are selected, add the first cell
        updatedSelection.add(index);
      }
    }

    setSelectedCells(updatedSelection);
  };

  const renderInputField = (label, value, onChange, width = "100px") => (
    <div style={{ display: "inline-block", marginRight: "10px" }}>
      <label style={{ marginRight: "5px" }}>{label}</label>
      <Input
        type="number"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        style={{ width }}
      />
    </div>
  );

  const renderControls = () => {
    switch (activeTab) {
      case "replace":
        return (
          <div className="flex gap-4 mb-4">
            {renderInputField("New Value:", firstValue, setFirstValue)}
            {renderInputField("Increasing By:", increaseBy, setIncreaseBy)}
            <Select
              value={increaseType}
              onChange={setIncreaseType}
              style={{ width: "60px", marginRight: "10px" }}
            >
              <Option value="#">#</Option>
              <Option value="%">%</Option>
            </Select>
            <Button type="" onClick={handleReplaceToolUpdate}>Update</Button>
          </div>
        );
      case "adjust":
        return (
          <div className="flex gap-4 mb-4">
            {renderInputField("Adjust Values By:", adjustValue, setAdjustValue)}
            <Select
              value={adjustType}
              onChange={setAdjustType}
              style={{ width: "60px", marginRight: "10px" }}
            >
              <Option value="#">#</Option>
              <Option value="%">%</Option>
            </Select>
            <Button type="" onClick={handleAdjustToolUpdate}>Update</Button>
          </div>
        );
      case "grow":
        return (
          <div className="flex gap-4 mb-4">
            {renderInputField("First Value:", firstValue, setFirstValue)}
            {renderInputField("Last Value:", lastValue, setLastValue)}
            <Button type="" className='' onClick={handleGrowToolUpdate}>Update</Button>
          </div>
        );
      default:
        return null;
    }
  };

  const filteredData = selectedCells.size === 0 
    ? data.map((point, index) => ({
        ...point,
        x: index + 1, // Ensures chronological numbering for all cells
      }))
    : data
        .filter((_, index) => selectedCells.has(index))
        .map((point, index, array) => ({
          ...point,
          x: array.indexOf(point) + 1,
        }));

  const tabItems = [
    {
      key: "replace",
      label: "Replace Tool",
      children: (
        <>
          <p>Use this tool to replace all values with a new single value or increase each point incrementally.</p>
          {renderControls()}
        </>
      ),
    },
    {
      key: "adjust",
      label: "Adjust Tool",
      children: (
        <>
          <p>Use this tool to adjust all values by a number or a percentage.</p>
          {renderControls()}
        </>
      ),
    },
    {
      key: "grow",
      label: "Grow (X → Y) Tool",
      children: (
        <>
          <p>Use this tool to set a range of values from a start (X) to an end (Y) point.</p>
          {renderControls()}
        </>
      ),
    },
  ];
  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    const updatedData = [...data];
  
    selectedCells.forEach((index) => {
      updatedData[index] = { ...updatedData[index], y: value };
    });
  
    setData(updatedData);  
  };
  
  return (
    <div>
      <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems} />

      {/* <ResponsiveContainer width="100%" height={400}>
        <LineChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" label={{ value: 'Time', position: 'insideBottomRight', offset: 0 }} />
          <YAxis label={{ value: 'Value', angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Line type="monotone" dataKey="y" stroke="#8884d8" dot={{ r: 6 }} />
        </LineChart>
      </ResponsiveContainer> */}

      <div className="table-responsive rounded" style={{ marginTop: '' }}>
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th style={{ color: 'white',backgroundColor:'#3D5A80'}}>Year</th>
              {months.map((month, i) => (
                <th key={i} style={{ color: 'white',backgroundColor:'#3D5A80' }}>{month}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {years.map((year, rowIndex) => (
              <tr key={rowIndex}>
                <td style={{ fontWeight: 'bold',color: 'white',backgroundColor:'#3D5A80' }}>{year}</td>
                {months.map((_, colIndex) => {
                  const dataIndex = rowIndex * 12 + colIndex;
                  return (
                    <td key={colIndex}
                        onMouseDown={() => handleMouseDown(dataIndex)}
                        onMouseMove={() => handleMouseMove(dataIndex)}
                        onMouseUp={handleMouseUp}
                        onClick={(e) => {
                          e.currentTarget.querySelector('input').focus();
                        }}
                        style={{ fontWeight: 'bold',color: 'white',backgroundColor:` ${selectedCells.has(dataIndex) ? "#fccd50" : "white"}` }}
                        >
                      <input
  type="number"
  className={`form-control p-0 border-0 ${selectedCells.has(dataIndex) ? "text-white" : ""}`}
  value={data[dataIndex] ? data[dataIndex].y : 0}
  onChange={handleInputChange}  // Change here
  style={{ 
    outline: 'none',
    width: `${Math.max((data[dataIndex]?.y.toString().length || 1) + 5, 3)}ch`,
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    textAlign: 'center',
  }}
/>

                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VariableTool;