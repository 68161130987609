import React, { useState, useEffect } from "react";
import { message, Steps, Spin, Modal, Typography, Input, Button } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import CardQuestionerFunding from "../Cards/CardsQuestionerFundingComponent";
import axios from "axios";
import prompts from "../../../../data/prompts.json";

const { Step } = Steps;
const { Title } = Typography;

const FundingComponent = () => {
  const [questions, setQuestions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [userResponses, setUserResponses] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingSuggestionsForQ2, setLoadingSuggestionsForQ2] = useState(false);
  const [loadingSuggestionsForQ4, setLoadingSuggestionsForQ4] = useState(false);
  const [loadingSuggestionsForQ5, setLoadingSuggestionsForQ5] = useState(false);
  const [revenuePlan, setRevenuePlan] = useState("");
  const [showRevenuePlanModal, setShowRevenuePlanModal] = useState(false);
  const [customResponse, setCustomResponse] = useState("");
  const loadingIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
  const [subQuestion, setSubQuestion] = useState({});
  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/questions?steps_id=6`
        );
        const filteredData = response.data.map((question) => {
            if ([25, 26, 27, 28, 29, 30, 31, 32, 33, 34].includes(question.id)) {
            return question;
            } else {
            return {
              ...question,
              responseOptions: [],
            };
            }
        });
        setQuestions(filteredData);
    // await console.log(questions);

      } catch (error) {
        console.error("Error fetching questions:", error);
        setError("Failed to load questions from the backend.");
        message.error("Failed to load questions from the backend.");
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
    console.log(questions);
  }, []);
  const handleAddCustomResponse = () => {
    if (!customResponse.trim()) {
      message.error("Custom response cannot be empty.");
      return;
    }

    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[currentStep].responseOptions.push(customResponse);
      return updatedQuestions;
    });

    setCustomResponse("");
    message.success("Custom response added successfully.");
  };
  const handleResponseChange = async (questionId, response) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: response,
    }));
// Check if the question requires sub-questions based on the response
if (shouldFetchSubQuestions(questionId, response)) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/subquestions?answer=${response}`
    );
    setSubQuestion(response.data);
  } catch (error) {
    console.error("Error fetching sub-questions:", error);
  }
} else {
  setSubQuestion([]);
}
setSubQuestion({
  "id": 25,
  "title": "Are the owners of the business planning on investing any personal funds into the business?",
  "description": "This question is asking if the individuals who own, direct, or have a partnership stake in the business are considering using their own personal money to support the business. This could be for starting up the business, expanding it, covering expenses, or any other financial needs the business may have.",
  "responseType": "button",
  "responseOptions": [
      "Yes",
      "No"
  ]
})
    // if (questionId === 17) {
    //   await handleLoadAISuggestions(response);
    // }

    // if (questionId === 12) {
    //   await handleLoadAISuggestionsForQ4(response);
    // }

    // if (questionId === 4) {
    //   await handleLoadAISuggestionsForQ5(userResponses[2], response);
    // }
  };
  const shouldFetchSubQuestions = (questionId, response) => {
    // Add your logic here to determine if sub-questions should be fetched
    // For example, you can check if the questionId and response match certain criteria
    const subQuestionCriteria = {
      1: ["yes", "maybe"],
      2: ["no"],
      // Add more criteria as needed
    };
  
    return subQuestionCriteria[questionId]?.includes(response);
  };
  const getBusinessDescription = () => {
    const description = localStorage.getItem("ONBOARDING_BUSSINES_DES");
    if (!description) {
      console.warn("ONBOARDING_BUSSINES_DES not found in local storage.");
      return "Default business description";
    }
    return description;
  };

  const handleLoadAISuggestions = async (
    response,
    regenerate = false,
    previousSuggestions = []
  ) => {
    if (!response) {
      message.error("Please answer the first question.");
      return;
    }
    console.log(response[0]);
    console.log(questions);
    let businessDescription = getBusinessDescription();
    let promptTemplate = prompts.staffing.loadJobs;
    let prompt = promptTemplate
      .replace("{contract_status}", response[0] === "Yes" ? "will" : "will not")
      .replace("{business_description}", businessDescription || "");

    if (regenerate && previousSuggestions.length) {
      const excludedSuggestions = previousSuggestions.join(", ");
      prompt += ` Avoid repeating these previous suggestions: ${excludedSuggestions}. Now, generate more distinctive and creative suggestions with unique ideas.`;
    }

    setLoadingSuggestionsForQ2(true);
    console.log(prompt);
    try {
      const result = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      let suggestions = result.data.choices[0].message.content;
      console.log(suggestions);
      suggestions = suggestions
        .split("\n")
        .map((suggestion) =>
          suggestion
            .replace(/^[0-9\.\-]+/, "")
            .replace(/[^a-zA-Z\s]/g, "")
            .trim()
            .split(" ")
            .slice(0, 2)
            .join(" ")
        )
        .filter((suggestion) => suggestion.split(" ").length === 2)
        .slice(0, 6);

      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === 18
            ? { ...question, responseOptions: suggestions }
            : question
        )
      );
    } catch (error) {
      console.error("Error loading AI suggestions:", error);
      message.error("Failed to load AI suggestions.");
    } finally {
      setLoadingSuggestionsForQ2(false);
    }
  };

  const handleLoadAISuggestionsForQ4 = async (
    response,
    regenerate = false,
    previousSuggestions = []
  ) => {
    if (!response) {
      message.error("Please answer question 3.");
      return;
    }

    let businessDescription = getBusinessDescription();
    let promptTemplate = prompts.staffing.payCalculate;
    let prompt = promptTemplate
      .replace("{Pay_status}", response[0] === "Yes" ? "will" : "will not")
      .replace("{business_description}", businessDescription || "");

    if (regenerate && previousSuggestions.length) {
      const excludedSuggestions = previousSuggestions.join(", ");
      prompt += ` Avoid repeating these previous suggestions: ${excludedSuggestions}. Now, expand the suggestions with more creative and distinctive ideas.`;
    }
    console.log(prompt);
    setLoadingSuggestionsForQ4(true);

    try {
      const result = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      let suggestions = result.data.choices[0].message.content;

      suggestions = suggestions
        .split("\n")
        .map((suggestion) =>
          suggestion
            .replace(/^[0-9\.\-]+/, "")
            .replace(/[^a-zA-Z\s]/g, "")
            .trim()
            .split(" ")
            .filter((word) => word)
            .slice(0, 2)
            .join(" ")
        )
        .filter((suggestion) => suggestion.split(" ").length === 2)
        .slice(0, 6);

      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === 13
            ? { ...question, responseOptions: suggestions }
            : question
        )
      );
    } catch (error) {
      console.error("Error loading AI suggestions for question 4:", error);
      message.error("Failed to load AI suggestions for question 4.");
    } finally {
      setLoadingSuggestionsForQ4(false);
    }
  };

  const handleLoadAISuggestionsForQ5 = async (
    q2Response,
    q4Response,
    regenerate = false,
    previousSuggestions = []
  ) => {
    if (!q2Response || !q4Response) {
      message.error("Please answer questions 2 and 4 first.");
      return;
    }

    let promptTemplate = prompts.revenue.loadRevenueStreams;

    let businessDescription = getBusinessDescription();
    let prompt = promptTemplate
      .replace("{business_description}", businessDescription || "")
      .replace("{q2_response}", q2Response)
      .replace("{q4_response}", q4Response);

    if (regenerate && previousSuggestions.length) {
      const excludedSuggestions = previousSuggestions.join(", ");
      prompt += ` Avoid repeating these previous suggestions: ${excludedSuggestions}. Now, expand the suggestions with more creative and distinctive ideas.`;
    }

    setLoadingSuggestionsForQ5(true);

    try {
      const result = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      let suggestions = result.data.choices[0].message.content;

      suggestions = suggestions
        .split("\n")
        .map((suggestion) =>
          suggestion
            .replace(/^[0-9\.\-]+/, "")
            .replace(/[^a-zA-Z\s]/g, "")
            .trim()
            .split(" ")
            .filter((word) => word)
            .slice(0, 2)
            .join(" ")
        )
        .filter((suggestion) => suggestion.split(" ").length === 2)
        .slice(0, 6);

      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === 5
            ? { ...question, responseOptions: suggestions }
            : question
        )
      );
    } catch (error) {
      console.error("Error loading AI suggestions for question 5:", error);
      message.error("Failed to load AI suggestions for question 5.");
    } finally {
      setLoadingSuggestionsForQ5(false);
    }
  };

  const saveResponseToDatabase = async (questionId, response) => {
    try {
      const userId = localStorage.getItem("USR_ID");

      if (!userId) {
        message.error("User ID not found in local storage.");
        return;
      }

      const payload = {
        question_id: questionId,
        answer: response,
        user_id: parseInt(userId),
      };

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-responses`,
        payload
      );
    } catch (error) {
      console.error("Error saving user response:", error);
      message.error("Failed to save user response.");
    }
  };

  const nextStep = () => {
    const currentQuestion = questions[currentStep];
    const currentResponse = userResponses[currentQuestion?.id];
    console.log(userResponses);
    if (!currentResponse) {
      message.error("Please provide a response before proceeding.");
      return;
    }

    saveResponseToDatabase(currentQuestion.id, currentResponse);

    if (currentStep < questions.length - 1) {
      if (currentStep === 3 && currentResponse[0] ==="No") {
        setCurrentStep(currentStep + 2);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const prevStep = () => {
    if(currentStep==5 && userResponses[15][0]=="No"){
      setCurrentStep(currentStep - 2);
    }
    else if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const formatRevenuePlan = (text) => {
    return text
      .replace(
        /(Projected Revenues For The Next Three Years:)/gi,
        "<h3>$1</h3>"
      )
      .replace(
        /(Identification And Analysis Of Key Revenue Streams:)/gi,
        "<h3>$1</h3>"
      )
      .replace(/(Calculation Of Gross Margins:)/gi, "<h3>$1</h3>")
      .replace(/(Evaluation Of Potential Growth Areas:)/gi, "<h3>$1</h3>")
      .replace(
        /(Recommendations For Improving Profitability:)/gi,
        "<h3>$1</h3>"
      )
      .replace(/(Revenue Strategies:)/gi, "<h3>$1</h3>")
      .replace(/(Conclusion:)/gi, "<h2>$1</h2>")
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/(?:\r\n|\r|\n)/g, "<br />");
  };

const handleSubmit = async () => {
    setLoading(true);
    const userId = localStorage.getItem('USR_ID');
    const currentQuestion = questions[currentStep];
    const currentResponse = userResponses[currentQuestion?.id];
    console.log(userResponses);

    if (!currentResponse) {
        message.error("Please provide a response before proceeding.");
        return;
    }

    saveResponseToDatabase(currentQuestion.id, currentResponse);
    if (!userId) {
        message.error('User ID not found in local storage.');
        setLoading(false);
        return;
    }

    const userAnswers = Object.entries(userResponses).map(([questionId, response]) => {
        const question = questions.find(q => q.id === parseInt(questionId));
        return {
            question: question?.title,
            userAnswer: response
        };
    });

    const formattedUserAnswers = userAnswers.map((answer, index) => `
        ${index + 1}. Question: "${answer.question}"
        User Answer: "${answer.userAnswer}"
    `).join('');

    let revenuePlanPromptTemplate = prompts.financialPlan.generatePlan;
    let revenuePlanPrompt = revenuePlanPromptTemplate.replace('{user_answers}', formattedUserAnswers);

    try {
        const result = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: 'gpt-3.5-turbo',
                messages: [{ role: 'user', content: revenuePlanPrompt }],
            },
            {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        const generatedRevenuePlan = result.data.choices[0].message.content;
        const formattedRevenuePlan = formatRevenuePlan(generatedRevenuePlan);
        setRevenuePlan(formattedRevenuePlan);
        setShowRevenuePlanModal(true);
        const payload = {
            USR_ID: parseInt(userId),
            GPT_Response: generatedRevenuePlan,
            steps_id: 1 
        };
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/gpt-response`, payload);
        } catch (saveError) {
            console.error('Error saving GPT response to the database:', saveError);
            message.error('Failed to save GPT response to the database.');
        }

    } catch (error) {
        console.error('Error generating the revenue plan:', error);
        message.error('Failed to generate the revenue plan.');
    } finally {
        setLoading(false);
        console.log("data sent successfully");
    }
};
    

  const handleModalOk = () => {
    setShowRevenuePlanModal(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          {loading && (
            <div className="loading-container">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          {error && <p className="text-danger">{error}</p>}

          {!loading && !error && questions.length > 0 && (
            <>
              <Steps current={currentStep} className="hide-on-small ">
                {questions.map((question, index) => (
                  <Step key={question.id} />
                ))}
              </Steps>

              <div className="style-steps row ">
                {questions.length > 0 && (
                  <div className="col-12">
                    <CardQuestionerFunding
                      title={questions[currentStep].title}
                      description={questions[currentStep].description}
                      responseType={questions[currentStep].responseType}
                      responseOptions={questions[currentStep].responseOptions}
                      placeholder={
                        questions[currentStep].placeholder ||
                        "Please provide a response"
                      }
                      onResponseChange={(response) =>
                        handleResponseChange(
                          questions[currentStep].id,
                          response
                        )
                      }
                      loadingSuggestions={
                        currentStep === 6
                          ? loadingSuggestionsForQ2
                          
                          
                          : false
                      }
                      extraLabel={
                        currentStep === 5 
                      }
                      regenerateButton={
                        currentStep === 6
                      }
                      onRegenerate={(() => {
                        if (currentStep === 6)
                          return () =>
                            handleLoadAISuggestions(userResponses[17], true);
                        // if(currentStep === 1)
                        //   return () =>
                        //     handleLoadAISuggestionsForQ4(userResponses[12], true);
                        return null;
                      })()}
                      currentStep={currentStep}
                      financialStep={2}
                      subQuestion={subQuestion}
                    />
                    {(currentStep === -1) && (
                      <div className="mt-2 ">
                      <Input
                        value={customResponse}
                        onChange={(e) => setCustomResponse(e.target.value)}
                        placeholder="Enter your custom response"
                      />
                      <Button onClick={handleAddCustomResponse} type="primary" style={{ backgroundColor: '#EE6C4D' }} className="rounded-pill mt-1 p-4">
                        Add Custom Response
                      </Button>
                    </div>
                    )}
                  </div>
                )}
              <div className="d-flex justify-content-between align-items-center ">
                {currentStep > 0 && (
                  <button
                    className="btn btn-previous-questioner"
                    onClick={prevStep}
                  >
                    Previous
                  </button>
                )}

                {currentStep < questions.length - 1 && (
                  <button
                    className={`btn btn-next-questioner ${
                      currentStep === 0 ? "full-width" : ""
                    }`}
                    onClick={nextStep}
                  >
                    Next
                  </button>
                )}

                {currentStep === questions.length - 1 && (
                  <button
                    className="btn btn-done-questioner"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                )}
              </div>
              </div>

              {revenuePlan && (
                <Modal
                  className="modelStyle"
                  title={
                    <Title level={3} style={{ color: "#1e212d" }}>
                      Generated Revenue Plan
                    </Title>
                  }
                  visible={showRevenuePlanModal}
                  onOk={handleModalOk}
                  closable={true}
                  closeIcon={
                    <CloseOutlined
                      style={{ color: "#3d5a80", fontSize: "20px" }}
                    />
                  }
                  onCancel={() => setShowRevenuePlanModal(false)}
                  footer={null}
                  style={{ zIndex: 999, maxWidth: "700px", margin: "0 auto" }}
                  bodyStyle={{
                    padding: "20px",
                    borderRadius: "10px",
                    height: "630px",
                    overflow: "hidden",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      overflowY: "auto",
                      padding: "20px",
                      borderRadius: "8px",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "1.6",
                      color: "#1e1e1e",
                      fontFamily: "inherit",
                    }}
                  >
                    <Typography>
                      <div dangerouslySetInnerHTML={{ __html: revenuePlan }} />
                    </Typography>
                  </div>
                </Modal>
              )}
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }
      `}</style>
    </div>
  );
};

export default FundingComponent;
