import React from 'react';
import Home from './Home';
import Model from './Model';
import Services from './Services';
import Features from './Features';
import FAQ from './FAQ';
import ContactUsSection from '../../components/pages/Landing/ContactUsSection';

const Main = () => {
  return (
    <>
        <Home />
        {/* <Model />
       <Services/> */}
      <div id="features">
        <Features/>
      </div>
        {/* <FAQ/> */}
      <div id="contact">
        <ContactUsSection/>
      </div>
    </>
  );
};

export default Main;
