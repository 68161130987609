import React from 'react';
import { Divider } from "antd";
import '../../../assets/styles/pages/plans.scss';
import Lottie from "lottie-react";
import correct from '../../../assets/lottie/correct.json';

const PlansCard = () => {
    const plans = [
        {
            name: 'Free',
            price: '0',
            features: [
                "Cash flow management tools",
                "Budgeting templates",
                "Expense tracking",
                "Limited financial reports",
            ],
            popular: false,
        },
        {
            name: 'Paid ProPlan',
            price: '29.99',
            features: [
                "All Free features",
                "Real-time financial projections",
                "Cash flow forecasting",
                "Integration with accounting software",
                "Scenario-based forecasting",
                "Custom financial reports",
            ],
            popular: true,
        },
        {
            name: 'Premium Growth Plan',
            price: '49.99',
            features: [
                "All Pro features",
                "Advanced scenario modeling",
                "Personalized financial insights",
                "Real-time dashboard with KPIs",
                "Chat support",
            ],
            popular: false,
        },
    ];

    return (
        <div className="plansSection">
            <div className="container">
                <h2 className="text-center mb-4">Simple. Affordable Plans for Everyone</h2>
                <Divider />
                <div className="row justify-content-center mt-4">
                    {plans.map((plan, index) => (
                        <div
                            className={`plan-card ${
                                plan.popular ? 'plan-card-popular' : 'plan-card-default'
                            }`}
                            key={index}
                        >
                            <h5 className="plan-title">
                                {plan.name}{' '}
                                {plan.popular && <span className="badge-popular">MOST POPULAR</span>}
                            </h5>
                            <h4 className="plan-price">
                                $ {plan.price} <span>/ month</span>
                            </h4>
                            <button className="btn btn-plan-select">Get Started</button>
                            <div className="features-container mt-4">
                                {plan.features.map((feature, i) => (
                                    <div className="feature-row d-flex align-items-center mb-3" key={i}>
                                        <div className="icon-container">
                                            <Lottie className="correct-logo" animationData={correct} />
                                        </div>
                                        <div className="feature-text ms-3">{feature}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PlansCard;
