import '../../assets/styles/pages/register.scss';
import Lottie from 'lottie-react';
import registerLottie from '../../assets/lottie/Signup.json';
import { Link } from 'react-router-dom';
import { Divider, message, Steps } from 'antd';
import { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';

const Register = () => {
    const [formData, setFormData] = useState({
        USR_Username: '',
        USR_Password: '',
        USR_Password_confirmation: '',
        USR_Email: '',
        USR_First_Name: '',
        USR_Last_Name: '',
        USR_Phone: '',
        USR_Phone_country_code: '',
        USR_Google_ID: '',
        USR_Free_Trial: true,
        USR_Country_Code: 'US',
        USR_Active: true,
        USR_email_verified: false,
        // Removed USR_City, USR_State, USR_Post_code
    });

    const handleChange = e => {
        const { name, value } = e.target;

        if (name === "USR_Phone_country_code") {
            const numericValue = value.replace(/\D/g, '').slice(0, 3);
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: numericValue
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);

    const steps = [
        {
            title: 'Personal Information',
            content: (
                <>
                    <div className="row mt-4">
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control"
                                name="USR_First_Name"
                                placeholder="First name"
                                value={formData.USR_First_Name}
                                onChange={handleChange}
                                aria-label="First Name"
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_Last_Name"
                                placeholder="Last Name"
                                value={formData.USR_Last_Name}
                                onChange={handleChange}
                                aria-label="Last Name"
                            />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_Username"
                                placeholder="Username"
                                value={formData.USR_Username}
                                onChange={handleChange}
                                aria-label="Username"
                            />
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: 'Contact Information',
            content: (
                <>
                    <div className="row mt-4">
                        <div className="col-6">
                            <input
                                type="email"
                                className="form-control form-design"
                                name="USR_Email"
                                placeholder="Email example@gmail.com"
                                value={formData.USR_Email}
                                onChange={handleChange}
                                aria-label="Email"
                            />
                        </div>
                        <div className="col-2">
                            <input
                                type="text"
                                className="form-control second-page form-design"
                                name="USR_Phone_country_code"
                                placeholder="(e.g. +1)"
                                value={formData.USR_Phone_country_code}
                                onChange={handleChange}
                                aria-label="Country Code"
                                maxLength={3}
                            />
                        </div>
                        <div className="col-4">
                            <input
                                type="text"
                                className="form-control second-page form-design"
                                name="USR_Phone"
                                placeholder="Phone Number"
                                value={formData.USR_Phone}
                                onChange={handleChange}
                                aria-label="Phone Number"
                            />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-6">
                            <input
                                type="password"
                                className="form-control second-page form-design"
                                name="USR_Password"
                                placeholder="Password"
                                value={formData.USR_Password}
                                onChange={handleChange}
                                aria-label="Password"
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="password"
                                className="form-control second-page form-design"
                                name="USR_Password_confirmation"
                                placeholder="Confirm Password"
                                value={formData.USR_Password_confirmation}
                                onChange={handleChange}
                                aria-label="Confirm Password"
                            />
                        </div>
                    </div>
                </>
            ),
        }
        // Removed the third step (Account Information)
    ];

    const next = () => {
        if (current === steps.length - 1) {
            handleSubmit();
        } else {
            if (validateStep(current)) {
                setCurrent(current + 1);
            }
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps.map(item => ({
        key: item.title,
        title: item.title,
    }));

    const passwordPatterns = [
        {
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{12,}$/
        },
        {
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/
        },
        {
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/
        },
        {
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/
        }
    ];

    const validatePassword = (password, confirmPassword) => {
        if (password !== confirmPassword) {
            message.error('Password does not match the confirmation');
            return false;
        }
        const matchedPattern = passwordPatterns.find(patternObj => patternObj.regex.test(password));
        if (!matchedPattern) {
            message.error('Your password does not meet the required criteria. Please include uppercase, lowercase, numbers, special characters, and ensure sufficient length.');
            return false;
        }
        return true;
    };

    const validateStep = (step) => {
        if (step === 0) {
            const { USR_First_Name, USR_Last_Name, USR_Username } = formData;

            if (!USR_First_Name || !USR_Last_Name || !USR_Username) {
                message.error('All fields in this step are required.');
                return false;
            }

            if (!/^[a-zA-Z]+$/.test(USR_First_Name)) {
                message.error('First Name should only contain letters.');
                return false;
            }

            if (!/^[a-zA-Z]+$/.test(USR_Last_Name)) {
                message.error('Last Name should only contain letters.');
                return false;
            }

            if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(USR_Username)) {
                message.error('Username must be at least 6 characters long and contain both letters and numbers.');
                return false;
            }

        } else if (step === 1) {
            const { USR_Email, USR_Phone_country_code, USR_Phone, USR_Password, USR_Password_confirmation } = formData;

            if (!USR_Email || !USR_Phone_country_code || !USR_Phone || !USR_Password || !USR_Password_confirmation) {
                message.error('All fields in this step are required.');
                return false;
            }

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(USR_Email)) {
                message.error('Invalid email format.');
                return false;
            }

            const phoneRegex = /^[0-9]{10,15}$/;
            if (!phoneRegex.test(USR_Phone)) {
                message.error('Invalid phone number format.');
                return false;
            }

            const countryCode = formData.USR_Phone_country_code;
            if (!/^\d{2,3}$/.test(countryCode)) {
                message.error('Country code must be 2 to 3 digits.');
                return false;
            }

            if (!validatePassword(USR_Password, USR_Password_confirmation)) {
                return false;
            }

        }
        // Removed validation for step 2 (Account Information)
        return true;
    };

    const validateFormData = () => {
        for (let i = 0; i < steps.length; i++) {
            if (!validateStep(i)) {
                return false;
            }
        }
        return true;
    };

    const apiURL = `${process.env.REACT_APP_API_BASE_URL}/auth/register`;
    const handleSubmit = async () => {
        if (!validateFormData()) return;

        const payload = {
            ...formData,
            USR_First_Last: `${formData.USR_First_Name} ${formData.USR_Last_Name}`,
            USR_Source: 1,
            // Removed USR_City, USR_State, USR_Post_code from payload
        };
        setLoading(true);

        try {
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            console.log('Response status:', response.status);
            const data = await response.json();

            console.log('Response data:', data);

            if (response.status === 201) {
                localStorage.setItem('Token', data.token);
                localStorage.setItem('USR_ID', data.user.USR_ID);
                localStorage.setItem('user_name', data.user.USR_Username);
                message.success('Registration successful');

                setTimeout(() => {
                    window.location.href = `/onboarding/${data.user.USR_ID}`;
                }, 2000);
            } else {
                console.error('Error:', data);
                message.error('Registration failed');
            }
        } catch (error) {
            console.error('Network or unexpected error:', error);
            message.error('Failed to register. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        const { credential } = credentialResponse;

        if (credential) {
            const decoded = jwtDecode(credential);

            const { email, name, sub: googleId } = decoded;

            try {
                // Use POST to send the Google user information to the backend
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/google-login`, {
                    email,
                    name,
                    google_id: googleId,
                });

                if (response.data) {
                    console.log(response.data)
                    // Handle success
                    localStorage.setItem('Token', response.data.token);
                    localStorage.setItem('USR_ID', response.data.user.USR_ID);
                    localStorage.setItem('user_name', response.data.user.USR_First_Name);
                    localStorage.setItem('welcome_message_shown', 'false');
                    message.success('Login successful!');
                    window.location.href = `/onboarding/${response.data.user.USR_ID}`;
                }
            } catch (error) {
                message.error('Google login failed');
            }
        } else {
            message.error('Google login failed');
        }
    };

    return (
        <>
            <div className="register-section">
                <div className='container'>
                    <div className="row">
                        <div className="col-5">
                            <Lottie className="register-logo" animationData={registerLottie} />
                        </div>
                        <div className="col-7 form-desin-login register-design d-flex justify-content-center align-items-center">
                            <form onSubmit={e => {
                                e.preventDefault();
                                if (current === steps.length - 1) {
                                    handleSubmit();
                                } else {
                                    next();
                                }
                            }}>
                                <div className='text-center'>
                                    <h2>Create Account</h2>
                                    <p>Create Your Account Now And Enjoy Our Service</p>
                                </div>

                                <Steps current={current} items={items} />
                                <div className='register style-steps'>{steps[current].content}</div>
                                <div className='text-center btn-responsive' style={{ marginTop: 24 }}>
                                    {current > 0 && (
                                        <button type="button" className='btn btn-previous-register m-3' onClick={prev}>
                                            Previous
                                        </button>
                                    )}
                                    {current === steps.length - 1 && (
                                        <button type="submit" className='btn btn-done-register' disabled={loading}>
                                            {loading ? 'Creating Account...' : 'Create Account'}
                                        </button>
                                    )}
                                    
                                    {current < steps.length - 1 && (
                                        <button type="button" className='btn btn-next-register' onClick={next}>
                                            Next
                                        </button>
                                    )}
                                </div>

                                <Divider />

                                <div className='row mt-3 justify-content-between align-items-center'>
                                    <div className="col-4"><p className='text-center'>Or continue with</p></div>
                                </div>

                                <div className='row justify-content-center'>
                                    <div className="col-6">
                                        <GoogleLogin
                                            onSuccess={handleGoogleSuccess}
                                            onError={() => {
                                                message.error('Google registration failed');
                                            }}
                                            useOneTap
                                        />
                                    </div>
                                </div>

                                <span className='text-center d-flex justify-content-center mt-3'>
                                    Already have an account? <Link to='/login'><span className='span-route p-2'>Login</span></Link>
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
