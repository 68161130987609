// Libraries
import { useEffect, useState } from "react";
import { Spin } from "antd";
import axios from "axios";

// Styles
import "../../../../assets/styles/system/cards.scss";
import "react-datepicker/dist/react-datepicker.css";

// Tools
import DropdownTool from "../Tools/DropdownTool";

const CardQuestionerRevenue = ({
  Question,
  UserselectedResponse,
  DropdownResponses,
  Push_Followups,
  Pop_Followups,
  Fetch_Options,
  onResponseChange,
  onDropdownResponseChange,
  currentStep,
}) => {
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [dropdownResponses, setDropdownResponses] = useState(DropdownResponses ?? []);
  const [dropdownQuestions, setDropdownQuestions] = useState([]);

  useEffect(() => {
    // selectedResponse handler
    const initializeSelectedResponse = () => {
      if (UserselectedResponse) {
        return UserselectedResponse;
      } else {
        switch (Question.responsetype) {
          case "One-User-Selection":
          case "One-combobox-Selection":
          case "User-Input-Percentage":
            return { Response: "" };
          case "Multi-User-Selection":
            return [];
          default:
            return [];
        }
      }
    };

    const initializeDropdownQuestions = () => {
      if (Question?.dropdown) {
        return Question.dropdown;
      }
      return [];
    };

    const initializeDropdownResponses = () => {
      setDropdownResponses(DropdownResponses ?? []);
    };

    setSelectedResponse(initializeSelectedResponse());
    setDropdownQuestions(initializeDropdownQuestions());
    initializeDropdownResponses()
  },[currentStep, Question, UserselectedResponse]);

  const Push_DropdownQuestions = (Questions) => {
    setDropdownQuestions((prevQuestions) => {
      let updatedQuestions = [...prevQuestions];
  
      Questions.forEach(question => {
        const exists = prevQuestions.some(prevQuestion => prevQuestion.id === question.id);
        if (!exists) {
          updatedQuestions.push(question);
        }
      });
  
      Question.dropdown = updatedQuestions;
      return updatedQuestions;
    });
  };

  const Pop_DropdownQuestions = ($ListQuestions) => {
    console.log("List Questions", $ListQuestions);
    let Question_Stack = [];

    $ListQuestions.forEach((Question) => {
      Question_Stack.push(Question);
    });

    let Questions = [];

    while (Question_Stack.length > 0) {
      let Current_Question = Question_Stack.pop();
      Questions.push(Current_Question);

      switch (Current_Question?.responsetype) {
        case "One-combobox-Selection":
          const response = dropdownResponses.find((response) => response.QuestionID === Current_Question.id);
          const selectedOption = Current_Question?.options.find((option) => option.choice === response?.Response);

          if(selectedOption){
            selectedOption?.listQuestions.forEach((option) => {
              Question_Stack.push(option);
            });
          }
          break;

        default:
          break;
      }
    }

    setDropdownQuestions((prevQuestions) => {
      const followupQuestionIds = Questions.map((q) => q.id);
      const filteredQuestions = prevQuestions.filter((q) => !followupQuestionIds.includes(q.id));
      Question.dropdown = filteredQuestions;
      return filteredQuestions;
    });

    setDropdownResponses((prevResponses) => {
      const filteredResponses = prevResponses.filter((response) => !Questions.find((question) => question.id === response.QuestionID));
      onDropdownResponseChange(filteredResponses);
      console.log("Filtered Responses", filteredResponses);
      return filteredResponses;
    });
  };

  // Dropdown Responses handlers
  const handleDropdownResponses = (response, QuestionID) => {
    setDropdownResponses((prevResponses) => {
      const questionresponse = prevResponses.find((res) => res.QuestionID === QuestionID);
      let updatedResponses;

      if (questionresponse) {
        updatedResponses = prevResponses.map((res) => {
          if (res.QuestionID === QuestionID) {
            return {
              ...res,
              ...response,
            };
          }
          return res;
        });
      } else {
        updatedResponses = [
          ...prevResponses,
          {
            QuestionID: QuestionID,
            ...response,
          },
        ];
      }

      onDropdownResponseChange(updatedResponses);
      return updatedResponses;
    });
  };

  const handle_One_Selection = (response) => {
    setSelectedResponse((prevResponse) => {
      const isSelected = prevResponse.Response === response.choice;
      let updatedResponse;

      if (isSelected) {
        const previousOption = Question.options.find((option) => option.choice === prevResponse.Response);
        if (previousOption?.followupQuestions) {
          Pop_Followups(previousOption.followupQuestions);
        }
        updatedResponse = { Response: "" };
      } else {
        const previousOption = Question.options.find((option) => option.choice === prevResponse.Response);

        if (previousOption?.followupQuestions) {
          Pop_Followups(previousOption.followupQuestions);
        }
        updatedResponse = { Response: response.choice };

        if (response?.followupQuestions) {
          Push_Followups(response.followupQuestions);
        }
      }

      onResponseChange(updatedResponse);
      return updatedResponse;
    });
  };

  const handle_Percentage_Input = (value) => {
    setSelectedResponse((prevResponse) => {
      const updatedResponse = {
        Response: value,
      };
      onResponseChange(updatedResponse);
      return updatedResponse;
    });
  };

  const handle_Combobox_Selection = (selectedChoice) => {
    setSelectedResponse((prevResponse) => {
      const isSelected = prevResponse?.Response === selectedChoice;
      
      const previousOption = Question.options.find((option) => option.choice === prevResponse.Response);
      console.log(previousOption)
      if (previousOption?.listQuestions) {
        console.log(previousOption)
        Pop_DropdownQuestions(previousOption.listQuestions);
      }

      let updatedResponse;

      if (isSelected) {
        updatedResponse = { Response: "" };
      } else {
        updatedResponse = { Response: selectedChoice };
        const selectedOption = Question.options.find((option) => option.choice === selectedChoice);
        Push_DropdownQuestions(selectedOption?.listQuestions);
      }

      onResponseChange(updatedResponse);
      return updatedResponse;
    });
  };

  const handle_Multi_Selection = (response) => {
    setSelectedResponse((prevResponse) => {
      console.log("Previous Response", prevResponse);
      const isSelected = prevResponse?.some((res) => res.Response === response);
      let updatedResponse;

      if (isSelected) {
        updatedResponse = prevResponse.filter((res) => res.Response !== response);

        if(Question?.followuptype === "Repeated") {
          console.log("Repeated Followup Questions", Question.followupQuestions);
          const updatedFollowupQuestions = Question.followupQuestions.map((followupQuestion) => ({
            ...followupQuestion,
            Keyelement: response,
          }));
          console.log("Updated Followup Questions", updatedFollowupQuestions);
          Pop_Followups(updatedFollowupQuestions);
        }
      } else {
        updatedResponse = [...prevResponse, { Response: response }];

        if (Question?.followuptype === "Non-repeated" && prevResponse.length === 0) {
          Push_Followups(Question.followupQuestions, Question.id);
        } else if (Question?.followuptype === "Repeated") {
          const updatedFollowupQuestions = Question.followupQuestions.map((followupQuestion) => ({
            ...followupQuestion,
            Keyelement: response,
          }));

          Push_Followups(updatedFollowupQuestions);
        }
      }

      if (updatedResponse.length === 0) {
        Pop_Followups(Question?.followupQuestions);
      }

      onResponseChange(updatedResponse);
      return updatedResponse;
    });
  };

  const handle_Variable_Selection = (response) => {
    setSelectedResponse((prevResponse) => {
      const existingResponseIndex = prevResponse.findIndex((res) => res.choice === response);
      let updatedResponses;

      if (existingResponseIndex > -1) {
        updatedResponses = [...prevResponse];
        updatedResponses[existingResponseIndex].state = updatedResponses[existingResponseIndex].state === "active" ? "inactive" : "active";
      } else {
        const newResponse = { choice: response, state: "active" };
        updatedResponses = [...prevResponse, newResponse];
      }

      onResponseChange(updatedResponses);
      return updatedResponses;
    });
  };

  const [options, setOptions] = useState([]);
  const [AddOption, setAddOption] = useState(false);
  const [UserInput, setUserInput] = useState("");

  const handle_User_AddOption = () => {
    setOptions((prevOptions) => {
      const updatedOptions = [...(Array.isArray(prevOptions) ? prevOptions : []), UserInput];
      return updatedOptions;
    });

    if(Question.optionstype === "Variable"){
      handle_Variable_Selection(UserInput);
    } else {
      handle_Multi_Selection(UserInput);
    }

    setAddOption(false);
  };

  /* AI Generation */
  const [loadingAI, setLoadingAI] = useState(false);

  const handleLoadAISuggestions = async () => {
    const prompt = Question.AI_Prompt.replace("[BUSINESS_Location]", localStorage.getItem("onboarding_Location"))
      .replace("[BUSINESS_DESCRIPTION]", localStorage.getItem("onboarding_Description"))
      .replace("[BUSINESS_CLASSIFICATION]", localStorage.getItem("onboarding_Classification"))
      .concat("\n\nEnsure that exactly four group names are provided. Do not provide more or less than four group names.");

    setLoadingAI(true);

    try {
      const result = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          }
        }
      );

      let suggestions = result.data.choices[0].message.content;

      suggestions = suggestions
        .split("\n")
        .map((suggestion) =>
          suggestion
            .replace(/[^a-zA-Z\s]/g, "")
            .trim()
            .split(" ")
            .filter((word) => word)
            .slice(0, 2)
            .join(" ")
        )
        .filter((suggestion) => suggestion.split(" ").length === 2)
        .slice(0, 6);

      setSelectedResponse([]);
      setOptions(suggestions);
      console.log(suggestions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAI(false);
    }
  };

  useEffect(() => {
    if (Question.optionstype === "AI Generated") {
      if (Array.isArray(UserselectedResponse) && UserselectedResponse.length > 0) {
        setOptions(UserselectedResponse.map((res) => res.Response));
      } else {
        handleLoadAISuggestions();
      }
    } else if (Question.optionstype === "Dynamic") {
      const options = Fetch_Options(Question.DynamicOptions);
      setOptions(options);
    } else if (Question.optionstype === "Variable") {
      setOptions((Question.options)?.map((option) => option.choice));
    }
  }, [currentStep, Question]);

  return (
    <div
      style={{
        height: "50vh",
        display: "flex",
        marginTop: "5vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="card-questioner">
        <h1>{Question?.span}</h1>
        <h2 className="mb-4">{Question?.Keyelement}</h2>
        <div className="response-area">
          <span>{Question?.title}</span>
          <p>{Question?.description}</p>

          {Question?.responsetype === "One-User-Selection" && (
            <div className="d-flex w-100 flex-wrap gap-1">
              {Question.options.map((option, index) => (
                <div className="mb-2" key={index}>
                    <button
                    className={`btn btn-choice ${
                        selectedResponse?.Response === option.choice ? "selected" : ""
                    }`}
                    onClick={() => handle_One_Selection(option)}
                    >
                    {option.choice}
                    </button>
                </div>
                ))}
            </div>
          )}

          {Question?.responsetype === "Multi-User-Selection" && (
            <div className="d-flex w-100 flex-wrap gap-1">
              {Question?.optionstype === "AI Generated" && (
                <>
                  <button className="btn btn-regenerate" onClick={handleLoadAISuggestions}>
                    {loadingAI ? <Spin /> : "Re-generate Options"}
                  </button>
                  <div className="d-flex w-100 flex-wrap gap-1">
                    {options.map((option, index) => (
                      <div className="mb-2" key={index}>
                        <button
                          className={`btn btn-choice ${
                            Array.isArray(selectedResponse) && selectedResponse.find((res) => res.Response === option) ? "selected" : ""
                          }`}
                          onClick={() => handle_Multi_Selection(option)}
                        >
                          {option}
                        </button>
                      </div>
                    ))}
                    {Question?.UserEdit === "true" && (
                      <div className="mb-2">
                        {AddOption ? (
                          <div style={{ position: 'relative', width: '100%' }}>
                            <input
                              type="text"
                              onChange={(e) => setUserInput(e.target.value)}
                              placeholder="Enter your response"
                              required
                              style={{
                                border: '2px solid #EE6C4D',
                                width: '100%',
                                color: '#EE6C4D',
                                padding: '10px',
                                borderRadius: '32px',
                                height: '50px',
                                minWidth: '50px',
                                boxSizing: 'border-box',
                              }}
                            />
                            <button
                              onClick={() => setAddOption(false)}
                              style={{
                                position: 'absolute',
                                bottom: '10px',
                                right: '70px',
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: '#EE6C4D',
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handle_User_AddOption}
                              style={{
                                position: 'absolute',
                                bottom: '10px',
                                right: '10px',
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: '#EE6C4D',
                              }}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn btn-choice"
                            onClick={() => setAddOption(true)}
                          >
                            Add Option
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {Question?.optionstype === "Dynamic" && (
                <div className="d-flex w-100 flex-wrap gap-1">
                  {Array.isArray(options) && options.map((option, index) => (
                    <div className="mb-2" key={index}>
                      <button
                        className={`btn btn-choice ${
                          Array.isArray(selectedResponse) && selectedResponse.find((res) => res.Response === option) ? "selected" : ""
                        }`}
                        onClick={() => handle_Multi_Selection(option)}
                      >
                        {option}
                      </button>
                    </div>
                  ))}
                  {Question?.UserEdit === "true" && (
                    <div className="mb-2">
                      {AddOption ? (
                        <div style={{ position: 'relative', width: '100%' }}>
                          <input
                            type="text"
                            onChange={(e) => setUserInput(e.target.value)}
                            placeholder="Enter your response"
                            required
                            style={{
                              border: '2px solid #EE6C4D',
                              width: '100%',
                              color: '#EE6C4D',
                              padding: '10px',
                              borderRadius: '32px',
                              height: '50px',
                              minWidth: '50px',
                              boxSizing: 'border-box',
                            }}
                          />
                          <button
                            onClick={() => setAddOption(false)}
                            style={{
                              position: 'absolute',
                              bottom: '10px',
                              right: '70px',
                              backgroundColor: 'transparent',
                              border: 'none',
                              color: '#EE6C4D',
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handle_User_AddOption}
                            style={{
                              position: 'absolute',
                              bottom: '10px',
                              right: '10px',
                              backgroundColor: 'transparent',
                              border: 'none',
                              color: '#EE6C4D',
                            }}
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        <button
                          className="btn btn-choice"
                          onClick={() => setAddOption(true)}
                        >
                          Add Option
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}

              {Question?.optionstype === "Variable" && (
                <div className="d-flex w-100 flex-wrap gap-1">
                  {Array.isArray(options) && options.map((option, index) => (
                    <button  
                      key={index}
                      className={`btn btn-choice ${
                        Array.isArray(selectedResponse) && selectedResponse.find((res) => res.choice === option && res.state === "active") ? "selected" : ""
                      }`}  
                      onClick={() => handle_Variable_Selection(option)}
                    >
                      {option}
                    </button>
                  ))}
                  {AddOption ? (
                    <div style={{ position: 'relative', width: '100%' }}>
                      <input
                        type="text"
                        onChange={(e) => setUserInput(e.target.value)}
                        placeholder="Enter your response"
                        required
                        style={{
                          border: '2px solid #EE6C4D',
                          width: '100%',
                          color: '#EE6C4D',
                          padding: '10px',
                          borderRadius: '32px',
                          height: '50px',
                          minWidth: '50px',
                          boxSizing: 'border-box',
                        }}
                      />
                      <button
                        onClick={() => setAddOption(false)}
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          right: '70px',
                          backgroundColor: 'transparent',
                          border: 'none',
                          color: '#EE6C4D',
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handle_User_AddOption}
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          right: '10px',
                          backgroundColor: 'transparent',
                          border: 'none',
                          color: '#EE6C4D',
                        }}
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    <button
                      className="btn btn-choice"
                      onClick={() => setAddOption(true)}
                    >
                      Add Option
                    </button>
                  )}
                </div>
              )}
            </div>
          )}

          {Question?.responsetype === "User-Input-Percentage" && (
            <div className="d-flex w-100 flex-wrap gap-1">
              <input
                type="number"
                value={selectedResponse?.Response || ""}
                onChange={(e) => handle_Percentage_Input(e.target.value)}
                placeholder={"Enter percentage"}
                className="Q8input-response"
                required
              />
              <span className="Q8-dollar-icon">%</span>
            </div>
          )}

          {Question?.responsetype === "One-combobox-Selection" && (
            <>
              <div className="d-flex w-100 flex-wrap gap-1">
                <select
                  className="form-select"
                  value={selectedResponse?.Response || ""}
                  onChange={(e) => handle_Combobox_Selection(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {Question.options.map((option, index) => (
                    <option key={index} value={option.choice}>
                      {option.choice}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}

          <div style={{ marginTop: '20px' }}>
            {dropdownQuestions?.map((Question, index) => (
              <div key={index} style={{marginBottom: "20px"}}>
                <DropdownTool 
                  dropdownQuestion={Question} 
                  dropdownResponse={dropdownResponses.find((response) => response.QuestionID === Question.id)} 
                  handleDropdownResponses={(response, QuestionID) => {
                    handleDropdownResponses(response, QuestionID);
                  }}
                  Pop_DropdownQuestions={(Questions) => {
                    Pop_DropdownQuestions(Questions);
                  }}
                  Push_DropdownQuestions={(Questions) => {
                    Push_DropdownQuestions(Questions);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardQuestionerRevenue;